import { Link } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const PocketCinemaIntro = () => {
  return (
    <Row className="py-5">
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="mb-5">
        <h2 className="mb-4 fs-2 text-uppercase">
          <span className="fw-bolder">Pocket Cinema</span>
          <br />
          <span className="fw-bolder"> Smartphone Filmmaking Training</span>
        </h2>
        <p>
          This course is for you... if you share our passion for cinema and freedom of
          expression, are committed to a World beyond injustice, and are dedicated to
          accompany, celebrate, and collaborate responsibly. We encourage cross-community,
          cross-sector and cross-issue collaborations, investing equally in each others’
          issues and narrative goals.
        </p>
        <p>
          In film, it is necessary to know and understand what everyone on set is doing.
          In order to bring someone's vision to life, a deep understanding of who they are
          is required.
        </p>
        <p>
          We use peer to peer creative writing methodologies and remote filmmaking
          techniques. Our platform gives exposure, support and recognition to courageous
          marginalized individuals, bringing greater global awareness of sexual and gender
          minorities.
        </p>
        <p>
          One scholarship from each of the sixty-seven countires that still criminalizes
          homosexuality will be given to an individual from that country. We encourage
          inquisitive, passionate, motivated and engaged individuals with positive and
          cooperative attitudes to apply.
        </p>
        <p>No previous experience is required.</p>
        <p>Deadline: ongoing</p>
        <p className="mb-2">Applications are reviewed every season:</p>
        <ul style={{ paddingLeft: 0, listStyle: "none" }} className="mb-4">
          <li>March</li>
          <li>June</li>
          <li>September</li>
          <li>and December</li>
        </ul>
        <Button
          as={Link}
          to="/training/application-form"
          variant="outline-primary"
          size="lg"
          className="border-2 rounded-0"
        >
          Apply Today
        </Button>
      </Col>
      {/*<Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="mb-5">
        <h3 className="mb-3 fs-2 text-primary text-uppercase">Selection Process</h3>
        <p>
          One scholarship from each of the seventy-one countires that still criminalizes
          homosexuality will be given to an individual from that country. We encourage
          inquisitive, passionate, motivated and engaged individuals with positive and
          cooperative attitudes to apply.  
        </p>
      </Col>*/}
    </Row>
  )
}

export default PocketCinemaIntro
