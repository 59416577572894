import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Container from "react-bootstrap/Container"
import AdminHeader from "../AdminHeader"

const Admin = ({ user, children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    // scroll to top of route change
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <AdminHeader user={user} />
      <Container as="main" fluid="xl" className="mt-100 mb-5">
        {children}
      </Container>
    </>
  )
}

export default Admin
