import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Header from "../Header"

const Main = ({ user, adminUser, children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    // scroll to top of route change
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header user={user} adminUser={adminUser} />
      {children}
    </>
  )
}

export default Main
