import { Formik, Form } from "formik"
import * as Yup from "yup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import AddVideoFormFields from "./AddVideoFormFields"

// Yup
const videoSchema = Yup.object().shape({
  title: Yup.string().max(50, "Title is too long").required("Title is required"),
  description: Yup.string().required("Description is required"),
  categories: Yup.string(),
  poster: Yup.string(),
  vodAssetVideoId: Yup.string().required("Video file is required"),
})

const AddVideoForm = ({ handleAddNewVideo, handleLoading, loading }) => {
  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        categories: "",
        poster: "",
        vodAssetVideoId: "",
      }}
      validationSchema={videoSchema}
      onSubmit={(values, { resetForm }) => {
        const videoData = {
          title: values.title,
          description: values.description,
          categories: values.categories,
          poster: values.poster,
          vodAssetVideoId: values.vodAssetVideoId,
        }

        // add new video
        handleAddNewVideo(videoData)

        // reset form
        resetForm()
      }}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Row className="mb-5">
            <Col xs={12}>
              <AddVideoFormFields
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                handleLoading={handleLoading}
              />
            </Col>
            <Col xs={12} className="pt-2">
              <Button
                type="submit"
                variant="success"
                size="lg"
                className="w-100"
                disabled={loading}
              >
                Add Video
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default AddVideoForm
