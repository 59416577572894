import { Link } from "react-router-dom"
import Nav from "react-bootstrap/Nav"

const HeaderNav = ({ adminUser }) => (
  <Nav className="me-auto">
    <Nav.Link as={Link} to="/" eventKey="1">
      Home
    </Nav.Link>
    <Nav.Link as={Link} to="/about" eventKey="2">
      About
    </Nav.Link>
    <Nav.Link as={Link} to="/training" eventKey="3">
      Training
    </Nav.Link>
    <Nav.Link as={Link} to="/productions" eventKey="4">
      Productions
    </Nav.Link>
    {adminUser && (
      <Nav.Link as={Link} to="/admin" eventKey="5">
        Admin
      </Nav.Link>
    )}
  </Nav>
)

export default HeaderNav
