import { Field, ErrorMessage } from "formik"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import FormFieldText from "../FormFieldText"
import FormErrorMessage from "../FormErrorMessage"
import FormFieldTextArea from "../FormFieldTextArea"
import AddVideoFormFieldsImage from "./AddVideoFormFieldsImage"
import AddVideoFormFieldsVideo from "./AddVideoFormFieldsVideo"

const AddVideoFormFields = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleLoading,
}) => (
  <Row>
    <AddVideoFormFieldsVideo
      touched={touched}
      errors={errors}
      setFieldValue={setFieldValue}
      handleLoading={handleLoading}
    />
    <Col xs={12}>
      <Field
        name="title"
        label="Title"
        touched={touched.title}
        errors={errors.title}
        component={FormFieldText}
      />
      <ErrorMessage name="title" component={FormErrorMessage} />
    </Col>
    <Col xs={12}>
      <Field
        name="description"
        label="Description"
        touched={touched.description}
        errors={errors.description}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="description" component={FormErrorMessage} />
    </Col>
    <Col xs={12}>
      <Field
        name="categories"
        label="Categories"
        text="Add categories in lower case separated by commas"
        touched={touched.categories}
        errors={errors.categories}
        component={FormFieldText}
      />
      <ErrorMessage name="categories" component={FormErrorMessage} />
    </Col>
    <AddVideoFormFieldsImage
      touched={touched}
      errors={errors}
      setFieldValue={setFieldValue}
      handleLoading={handleLoading}
    />
  </Row>
)

export default AddVideoFormFields
