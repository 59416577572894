import Col from "react-bootstrap/Col"
import { Facebook, Instagram, Linkedin } from "react-bootstrap-icons"

const FooterSocial = () => (
  <Col xs={12} md={5} className="text-center my-4">
    <a
      className="mx-2 py-2"
      href="https://www.facebook.com/75shots"
      target="_blank"
      rel="noreferrer"
    >
      <Facebook color="white" size={36} />
    </a>
    <a
      className="mx-2 py-2"
      href="https://www.instagram.com/75shotsmovie/"
      target="_blank"
      rel="noreferrer"
    >
      <Instagram color="white" size={36} />
    </a>
    <a
      className="mx-2 py-2"
      href="https://www.linkedin.com/company/75-shots/about/?viewAsMember=true"
      target="_blank"
      rel="noreferrer"
    >
      <Linkedin color="white" size={36} />
    </a>
    {/*<a
      className="mx-2 py-2"
      href=""
      target="_blank"
    >
      <Youtube color="white" size={36} />
    </a>*/}
  </Col>
)

export default FooterSocial
