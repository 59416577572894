import TemplatePageSectionTrainingA from "../TemplatePageSectionTrainingA"

const TrainingPocketCinema = () => (
  <TemplatePageSectionTrainingA
    title="Pocket Cinema"
    subtitle1="Smartphone"
    subtitle2="Filmmaking"
    subtitle3="Training"
    imgLink="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1630048376/photo5879829371349481376_ybxxax.jpg"
    imgAlt="Students getting their certificate"
    btnText="Learn more"
    btnLink="/training/pocket-cinema"
  >
    <p>
      Our scholarships are available to one individual from each of the sixty-seven
      countries that criminalize homosexualiy. No previous experience is required. 
    </p>
  </TemplatePageSectionTrainingA>
)

export default TrainingPocketCinema
