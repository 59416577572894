const newsletterSubscriptionReducer = (state, action) => {
  switch (action.type) {
    case "SET NEWSLETTER SUBSCRIPTIONS":
      return { subscriptions: action.payload }
    case "CREATE NEWSLETTER SUBSCRIPTION":
      return {
        subscriptions: [action.payload, ...state.subscriptions],
      }
    case "DELETE NEWSLETTER SUBSCRIPTION":
      return {
        subscriptions: state.subscriptions.filter((item) => item.id !== action.payload),
      }
    default:
      return state
  }
}

export default newsletterSubscriptionReducer
