import Form from "react-bootstrap/Form"

const FormFieldText = ({ field, label, text, touched, errors }) => (
  <Form.Group className="mb-1 pt-3">
    <Form.Label>{label}</Form.Label>
    <Form.Control type="text" size="lg" {...field} isInvalid={touched && errors} />
    {text && <Form.Text className="text-muted">{text}</Form.Text>}
  </Form.Group>
)

export default FormFieldText
