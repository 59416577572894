import Accordion from "react-bootstrap/Accordion"
import VideoListItem from "./VideoListItem"

const VideoList = ({ videos, handleUpdateVideoList, handleDeleteVideo }) => (
  <Accordion>
    {videos &&
      videos.map((item, index) => (
        <VideoListItem
          key={index}
          item={item}
          index={index}
          handleUpdateVideoList={handleUpdateVideoList}
          handleDeleteVideo={handleDeleteVideo}
        />
      ))}
  </Accordion>
)

export default VideoList
