import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DonatePayPalSubscriptionButton from "./DonatePayPalSubscriptionButton"

const DonatePayPalSubscription = ({ handleAddNewDonation }) => {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    // "client-id": "test",
    currency: "CAD",
    intent: "subscription",
    vault: true,
    //debug: true,
  }

  const subscriptionTypes = [
    {
      amount: 5,
      planId: "P-52504355A9798925EMMTXHGQ",
      benefit:
        "Provides local transportation, safe space venue & snacks for cast & crew.",
    },
    {
      amount: 10,
      planId: "P-6P090873MS8057810MNCEWEY",
      benefit: "Provides apps & phone accessories.",
    },
    {
      amount: 25,
      planId: "P-5YY811534R528893AMNCEZAI",
      benefit:
        "Provides equipment, storage, car rental, venue rental, per diem, costumes, make-up, hair.",
    },
    {
      amount: 50,
      planId: "P-21Y80193XA368121XMNDAQZI",
      benefit:
        "Provides professional fees, copyrights, licensing fees, shipping, data plans & web hosting.",
    },
    {
      amount: 250,
      planId: "P-4SV78155KY0088540MNDATWY",
      benefit:
        "Provides a 10 weeks scholarship in Pocket Cinema Training to an individual from one of the sixty-eight countries that still criminalizes homosexuality. Participants receive 1 on 1 private courses over the course of 10 weeks and get to produce their first short film.",
    },
    {
      amount: 500,
      planId: "P-1XE958048A873802NMNDA3HY",
      benefit:
        "Provides 6 months scholarship in Pocket Cinema Training to an individual representing one of the sixty-eight countries that still criminalizes homosexuality. Participants receive 1 on 1 private courses over the course of a year, an iPhone with accesories and laptop computer.",
    },
  ]

  return (
    <PayPalScriptProvider options={initialOptions}>
      <Row className="mx-3 mt-5">
        <Col xs={12} className="mb-3 text-center">
          <h3 className="mb-4 text-uppercase fw-bold">Monthly Subscriptions</h3>
        </Col>
        {subscriptionTypes.map((subscription, index) => (
          <DonatePayPalSubscriptionButton
            key={index}
            subscription={subscription}
            handleAddNewDonation={handleAddNewDonation}
          />
        ))}
      </Row>
    </PayPalScriptProvider>
  )
}
export default DonatePayPalSubscription
