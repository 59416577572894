import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
//import { ArrowRight } from "react-bootstrap-icons"
import FormFieldEmailCompact from "./FormFieldEmailCompact"
import FormErrorMessage from "./FormErrorMessage"
import FormFieldTextCompact from "./FormFieldTextCompact"

// Yup
const newsletterSignUpSchema = Yup.object().shape({
  firstname: Yup.string().min(1).required("First name is required"),
  lastname: Yup.string()
    .min(2, "Last name is too short")
    .required("Last name is required"),
  email: Yup.string()
    .email("Must be a valid email address")
    .required("Contact email is required"),
})

const FormNewsletterSignUp = ({ handleAddNewSubscription }) => (
  <Formik
    initialValues={{
      firstname: "",
      lastname: "",
      email: "",
    }}
    validationSchema={newsletterSignUpSchema}
    onSubmit={(values, { resetForm }) => {
      const newSubscriber = {
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
      }
      handleAddNewSubscription(newSubscriber)
      // reset form
      resetForm()
    }}
  >
    {({ touched, errors }) => (
      <Form>
        <Row className="mt-1 mx-3 mx-sm-5 mx-md-1 mx-lg-5 g-1 pl-1">
          <Col xs={12} className="my-2 text-center">
            <h4 className="fs-4 text-white">Subscribe to our newsletter</h4>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-1">
            <Field
              name="firstname"
              label="First Name"
              placeholder="First Name"
              touched={touched.firstname}
              errors={errors.firstname}
              component={FormFieldTextCompact}
            />
            <ErrorMessage name="firstname" component={FormErrorMessage} />
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-1">
            <Field
              name="lastname"
              label="Last Name"
              placeholder="Last Name"
              touched={touched.lastname}
              errors={errors.lastname}
              component={FormFieldTextCompact}
            />
            <ErrorMessage name="lastname" component={FormErrorMessage} />
          </Col>
          <Col xs={12} md={4} className="mb-1">
            <Field
              name="email"
              placeholder="Email"
              touched={touched.email}
              errors={errors.email}
              component={FormFieldEmailCompact}
            />
            <ErrorMessage name="email" component={FormErrorMessage} />
          </Col>
          <Col xs={12} md={2} className="mb-1">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              className="w-100 text-white"
              style={{ paddingTop: "0.65em", paddingBottom: "0.65em" }}
            >
              Join
            </Button>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
)

export default FormNewsletterSignUp
