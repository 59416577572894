import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TrainingIntro from "./TrainingIntro"
//import TrainingGraduates from "./TrainingGraduates"
// import TrainingAside from "./TrainingAside"
import TrainingPocketCinema from "./TrainingPocketCinema"
// import TrainingQuote from "./TrainingQuote"
import TrainingGraduatesQuote from "./TrainingGraduatesQuote"

const Training = () => (
  <Container as="main" className="mt-72 overflow-hidden">
    <Row>
      <Col xs={12} className="p-0">
        <Image
          src="https://res.cloudinary.com/pocketcinema/image/upload/v1632450705/IMG_0037_xvz9r7.jpg"
          alt="header image"
          fluid
        />
      </Col>
      <TrainingIntro />
    </Row>
    <Row className="bg-primary">
      <Col xs={{ span: 10, offset: 1 }} className="py-4">
        <h2 className="py-5 lh-1 text-white text-uppercase header-page">
          <span className="d-block fw-bolder">A powerful tool in your pocket</span>
          <span className="fw-lighter"> that can change the world</span>
        </h2>
      </Col>
    </Row>
    <TrainingPocketCinema />
    {/*<TrainingQuote />*/}
    <TrainingGraduatesQuote />
  </Container>
)

export default Training
