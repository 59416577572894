import { approveApplication } from "../../services/application"
import Accordion from "react-bootstrap/Accordion"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ApplicationListItem = ({
  item,
  index,
  handleUpdateApplicationList,
  handleConfirmDeleteApplication,
}) => {
  const approveItem = async () => {
    try {
      const response = await approveApplication(item.id)

      if (response) {
        handleUpdateApplicationList(response)
      }
    } catch (err) {
      //TODO: error handling
    }
  }

  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>
        {item.name}, {item.country}
      </Accordion.Header>
      <Accordion.Body>
        <Row className="mb-3">
          <Col as="p" xs={12} className="mb-0">
            <span className="fw-bold">Name:</span> {item.name}
          </Col>
          <Col as="p" xs={12} className="mb-0">
            <span className="fw-bold">Country:</span> {item.country}
          </Col>
          <Col as="p" xs={12} className="mb-0">
            <span className="fw-bold">Email:</span> {item.email}
          </Col>
          <Col as="p" xs={12} className="mb-0">
            <span className="fw-bold">Phone:</span> {item.phoneType}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as="p" xs={12}>
            <span className="fw-bold">Name 3 things you find beautiful in general</span>
            <br />
            {item.favoriteThings}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              What do you want to learn the MOST in this course?
            </span>
            <br />
            {item.courseAims}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">Describe yourself in 1-2 sentences</span>
            <br />
            {item.aboutMe}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">Add URL link to your favourite LGBTQ+ film</span>
            <br />
            {item.favoriteFilmUrl}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">Add links to your previous work?</span>
            <br />
            {item.linksToWork ? item.linksToWork : "-"}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">What do you do?</span>
            <br />
            {item.question_1}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              What steps did you take to get where you are today?
            </span>
            <br />
            {item.question_2}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">How do you stand out in your field?</span>
            <br />
            {item.question_3}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              Describe a project you are working on right now?
            </span>
            <br />
            {item.question_4}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              What's your style/perspective/taste? Do you have a project that represents
              this? (add link)
            </span>
            <br />
            {item.question_5}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              Which aspect of your filmmaking do you wish you could focus on more?
            </span>
            <br />
            {item.question_6}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">What is challenging you right now?</span>
            <br />
            {item.question_7}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              Let's bring out the time machine. What do you wish you could have told
              yourself, when, and why?
            </span>
            <br />
            {item.question_8}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              If you could talk to an expert to gain more insight on something, what would
              it be about?
            </span>
            <br />
            {item.question_9}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              What kind of opportunities are you looking for?
            </span>
            <br />
            {item.question_10}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              Describe your ideal project? The type of projects you would like to work on.
            </span>
            <br />
            {item.question_11}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              Which artist influences you? Who has helped you elevate your work? (add
              link)
            </span>
            <br />
            {item.question_12}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              How should someone approach you about working together?
            </span>
            <br />
            {item.question_13}
          </Col>
          <Col as="p" xs={12}>
            <span className="fw-bold">
              How do you stay creative? Where do you get your inspiration from?
            </span>
            <br />
            {item.question_14}
          </Col>
        </Row>
        <Row className="g-2">
          <Col xs={6}>
            <Button
              variant="outline-success"
              size="lg"
              className="w-100"
              disabled={item.approved}
              onClick={approveItem}
            >
              Approve
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              variant="outline-danger"
              size="lg"
              className="w-100"
              onClick={() => handleConfirmDeleteApplication(item.id)}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default ApplicationListItem
