import Col from "react-bootstrap/Col"

const BannerSectionText = () => (
  <Col xs={{ span: 10, offset: 1 }} className="p-3">
    <h2 className="m-0 pt-5 text-white text-uppercase header-mission">
      <span className="fw-bolder">Shooting Down Hatered </span>
      <br />
      <span className="fw-lighter">One Shot at a Time</span>
    </h2>
    <div className="my-5 fs-3 fw-light text-white">
      <p>
        A shot refers to both the image captured by a camera when the shutter clicks, and
        also to the action of a firearm. When 75 SHOTS began, there were seventy-five
        countries with homophobic laws, and we are working to steadily decrease that to
        none. Our goal is to ensure that one day there will be “zero shots“. Shooting down
        hatred one shot at a time through the power of cinema.
      </p>
      <p>
        Our wish is to Influence LGBTQ+ advocates, supporters, decision-makers, allies and
        activists to push legal reforms.
      </p>
    </div>
  </Col>
)

export default BannerSectionText
