import React, { useEffect, useReducer } from "react"
import { Link } from "react-router-dom"
import applicationReducer from "../../reducers/applicationReducer"
import donationReducer from "../../reducers/donationReducer"
import { getApplications } from "../../services/application"
import { getDonations } from "../../services/donation"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import AdminAddVideo from "../AdminAddVideo"

const AdminDashboard = ({ subscriptions }) => {
  const [state1, dispatch1] = useReducer(applicationReducer, { applications: [] })
  const [state2, dispatch2] = useReducer(donationReducer, { donations: [] })

  useEffect(() => {
    const fetchApplicationData = async () => {
      try {
        const applicationData = await getApplications()

        applicationData &&
          dispatch1({ type: "SET APPLICATIONS", payload: applicationData })
      } catch (err) {
        alert("Error fetching application data form the server")
      }
    }

    const fetchDonationData = async () => {
      try {
        const donationData = await getDonations()

        donationData && dispatch2({ type: "SET DONATIONS", payload: donationData })
      } catch (err) {
        alert("Error fetching donation data form the server")
      }
    }

    fetchApplicationData()
    fetchDonationData()
  }, [])

  const sortByPending = (arr) => {
    const pendingApplications = arr.filter((item) => item.approved === false)
    return pendingApplications.length
  }

  const sortByApproved = (arr) => {
    const approvedApplications = arr.filter((item) => item.approved === true)
    return approvedApplications.length
  }

  const calcTotalDonations = (arr) => {
    const totalDonations = arr.reduce((a, b) => a + parseInt(b.amount), 0)
    return totalDonations.toFixed(2)
  }

  return (
    <Row className="g-0 mx-2">
      <Col xs={12} className="mb-4 pt-4 text-center">
        <h3>Admin Dashboard</h3>
      </Col>
      <Col
        xs={12}
        sm={{ span: 10, offset: 1 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 3 }}
      >
        <Card className="mb-3">
          <Card.Body>
            <Card.Title as={Link} to="/admin-applications" className="fs-4">
              Applications
            </Card.Title>
            <Card.Subtitle className="my-2">
              Pending: {sortByPending(state1.applications)}
            </Card.Subtitle>
            <Card.Subtitle>Approved: {sortByApproved(state1.applications)}</Card.Subtitle>
          </Card.Body>
        </Card>
      </Col>
      <Col
        xs={12}
        sm={{ span: 10, offset: 1 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 3 }}
      >
        <Card className="mb-3">
          <Card.Body>
            <Card.Title as={Link} to="/admin-donations" className="fs-4">
              Donations
            </Card.Title>
            <Card.Subtitle className="mt-2">
              Total donations to date: {calcTotalDonations(state2.donations)} CAD
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </Col>
      <Col
        xs={12}
        sm={{ span: 10, offset: 1 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 3 }}
      >
        <Card className="mb-3">
          <Card.Body>
            <Card.Title as={Link} to="/admin-subscriptions" className="fs-4">
              Newsletter Subscriptions
            </Card.Title>
            <Card.Subtitle className="mt-2">
              Number of subscribers: {subscriptions.length}
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </Col>
      <Col
        xs={12}
        sm={{ span: 10, offset: 1 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 3 }}
      >
        <AdminAddVideo />
      </Col>
    </Row>
  )
}

export default AdminDashboard
