const donationReducer = (state, action) => {
  switch (action.type) {
    case "SET DONATIONS":
      return { donations: action.payload }
    default:
      return state
  }
}

export default donationReducer
