import React, { useState } from "react"
import config from "../../aws-exports"
import videoconfig from "../../aws-video-exports"
import { v4 as uuidv4 } from "uuid"
// import { Storage } from "aws-amplify"
import { Field, ErrorMessage } from "formik"
//import { storeVideoFile } from "../../storage/videoStorage"
import Col from "react-bootstrap/Col"
import FormFieldVideoFile from "../FormFieldVideoFile"
import FormErrorMessage from "../FormErrorMessage"

const AddVideoFormFieldsVideo = ({ touched, errors, setFieldValue, handleLoading }) => {
  const [progress, setProgress] = useState(null)
  const [error, setError] = useState(false)
  const uuid = uuidv4()

  const uploadVideo = (file, id) => {
    const fileExtension = file.name.toLowerCase().split(".")
    const name = `${id}.${fileExtension[fileExtension.length - 1]}`
    setProgress(0)
    handleLoading(true)

    // configure Storage to use input bucket
    Storage.configure({
      Auth: {
        region: config.aws_project_region, // REQUIRED - Amazon Cognito Region
        userPoolId: config.aws_user_pools_id,
        userPoolWebClientId: config.aws_user_pools_web_client_id,
        mandatorySignIn: false,
        //identityPoolId: config.aws_cognito_identity_pool_id, //REQUIRED - Amazon Cognito Identity Pool ID
      },
      AWSS3: {
        bucket: videoconfig.awsInputVideo,
        //identityPoolId: config.aws_cognito_identity_pool_id,
        region: config.aws_project_region,
      },
    })

    Storage.put(name, file, {
      contentType: file.type,
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`)
        // returns the file key (name)
        if (event.key) {
          handleLoading(false)
          setProgress(null)
        }
        // return event.key
      },
      progressCallback: (progress) => {
        const percent = Math.round((progress.loaded / progress.total) * 100)
        console.log("loding status: ", percent + "%")
        setProgress(percent)
      },
      errorCallback: (err) => {
        console.error("Unexpected error while uploading", err)
        setError(true)
      },
    })
  }

  const handleVideoInput = (file) => {
    const videoId = uuid
    // save video file to local state
    uploadVideo(file, videoId)
    // assign an id to the video
    setFieldValue("vodAssetVideoId", videoId)
  }

  return (
    <Col xs={12}>
      <Field
        name="vodAssetVideoId"
        label="Upload Video"
        touched={touched.vodAssetVideoId}
        errors={errors.vodAssetVideoId}
        handleVideoInput={handleVideoInput}
        component={FormFieldVideoFile}
        progress={progress}
        error={error}
      />
      <ErrorMessage name="vodAssetVideoId" component={FormErrorMessage} />
    </Col>
  )
}

export default AddVideoFormFieldsVideo
