import { Link } from "react-router-dom"
import { ArrowRight } from "react-bootstrap-icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

const TemplatePageSectionTrainingA = ({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  imgLink,
  imgAlt,
  btnText,
  btnLink,
  children,
}) => {
  return (
    <Row className="bg-light align-items-center section-padding">
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 5, offset: 1 }}>
        <Image src={imgLink} alt={imgAlt} fluid roundedCircle />
      </Col>
      <Col xs={{ span: 10, offset: 1 }} md={{ span: 5, offset: 0 }} className="py-5">
        {/*<h5 className="mb-4 fs-6 text-primary fw-light text-uppercase">Training</h5>*/}
        <h3 className="mb-4 fs-2 lh-1 text-uppercase">
          <span className="fw-lighter">{title} </span>
          <br />
          <span className="fw-bolder">{subtitle1}</span>
          <br />
          <span className="fw-bolder">{subtitle2}</span>
          <br />
          <span className="fw-bolder">{subtitle3}</span>
        </h3>
        <div className="mb-4">{children}</div>
        <Button
          as={Link}
          to={btnLink}
          variant="link"
          className="p-0 text-decoration-none text-uppercase fw-light"
        >
          <ArrowRight size={18} className="mb-1" /> {btnText}
        </Button>
      </Col>
    </Row>
  )
}

export default TemplatePageSectionTrainingA
