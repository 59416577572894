import TemplatePageSectionC from "../TemplatePageSectionC"

const TrainingSection = () => (
  <TemplatePageSectionC
    title="Pocket Cinema"
    subtitle1="Smartphone"
    subtitle2="Filmmaking"
    subtitle3="Training"
    imgLink="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1630048376/photo5879829371349481376_ybxxax.jpg"
    imgAlt="Students getting their certificate"
    pageLink="/training"
    btnText="Learn more"
    btnLink="/training/pocket-cinema"
  >
    <p>
      You will learn the basics of filmmaking, how to tell your own story and develop your
      own unique style. This course is tailored for you.
    </p>
  </TemplatePageSectionC>
)

export default TrainingSection
