import { Field, ErrorMessage } from "formik"
import Col from "react-bootstrap/Col"
import FormFieldEmail from "../FormFieldEmail"
import FormFieldPassword from "../FormFieldPassword"
import FormErrorMessage from "../FormErrorMessage"

const SignInFormFields = ({ touched, errors }) => (
  <Col
    xs={{ span: 10, offset: 1 }}
    md={{ span: 8, offset: 2 }}
    lg={{ span: 6, offset: 3 }}
  >
    <Field
      name="email"
      label="Email"
      touched={touched.email}
      errors={errors.email}
      component={FormFieldEmail}
    />
    <ErrorMessage name="email" component={FormErrorMessage} />
    <Field
      name="password"
      label="Password"
      touched={touched.password}
      errors={errors.password}
      component={FormFieldPassword}
    />
    <ErrorMessage name="password" component={FormErrorMessage} />
  </Col>
)

export default SignInFormFields
