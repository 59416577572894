import { generateClient } from "aws-amplify/api"
/* queries and mutations */
import { listApplications as ListApplications } from "../graphql/queries"
import {
  createApplication as CreateApplication,
  updateApplication as UpdateApplication,
  deleteApplication as DeleteApplication,
} from "../graphql/mutations"
// import { currentAuthenticatedUser } from "./auth"

const client = generateClient()

// const isGuest = async () => {
//   try {
//     const response = await currentAuthenticatedUser()

//     if (response === undefined) {
//       return true
//     } else {
//       return false
//     }
//   } catch (err) {
//     console.log("isGuest error: ", err)
//   }
// }

export const getApplications = async () => {
  // const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: ListApplications,
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    // get list of applications
    return result.data.listApplications.items ?? []
  } catch (err) {
    console.log("error fetching applications...", err)
  }
}

export const createNewApplication = async (data) => {
  // const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: CreateApplication,
      variables: {
        input: data,
      },
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    // get newly created application
    return result.data.createApplication ?? null
  } catch (err) {
    console.log("error creating an application...", err)
  }
}

export const approveApplication = async (id) => {
  try {
    const result = await client.graphql({
      query: UpdateApplication,
      variables: {
        input: { id, approved: true },
      },
    })
    // get updated asset
    return result.data.updateApplication ?? null
  } catch (err) {
    console.log("error updating an application...", err)
  }
}

export const removeApplication = async (id) => {
  try {
    const result = await client.graphql({
      query: DeleteApplication,
      variables: {
        input: { id },
      },
    })
    // get updated asset
    return result.data.deleteApplication ?? null
  } catch (err) {
    console.log("error deleting an application...", err)
  }
}
