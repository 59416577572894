import { Formik, Form } from "formik"
import * as Yup from "yup"
import { signInUser } from "../../services/auth"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import SignInFormFields from "./SignInFormFields"

// Yup
const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email address")
    .required("Contact email is required"),
  password: Yup.string().required("Password is required"),
})

const SignInForm = () => (
  <Formik
    initialValues={{
      email: "",
      password: "",
    }}
    validationSchema={signInSchema}
    onSubmit={(values) => {
      signInUser(values.email, values.password)
    }}
  >
    {({ touched, errors }) => (
      <Form>
        <Row>
          <SignInFormFields touched={touched} errors={errors} />
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="pt-3"
          >
            <Button type="submit" variant="success" size="lg" className="w-100">
              Sign In
            </Button>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
)

export default SignInForm
