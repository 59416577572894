import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

const DonateBanner = () => {
  return (
    <Row style={{ backgroundColor: "black" }}>
      <Col xs={12} lg={{ span: 10, offset: 1 }} className="text-white my-2 my-md-4 p-4">
        <Row className="g-3" style={{ fontFamily: "helvetica" }}>
          <Col xs={12} className="pt-1 text-center">
            <h4 className="mb-3 fs-3 text-uppercase">
              Subscribe to the Cinema Feast Collection
            </h4>
            <p>
              100% of the proceeds go towards 75 SHOTS: Filmmaking Mentorship Program for
              LGBTQ+ people living in oppressive regimes. Watch the best in independent
              Queer Cinema from the Global South & Diaspora along with Premieres by 75
              SHOTS POCKET CINEMA Productions, on any device, anywhere, anytime.
            </p>
          </Col>
          <Col xs={12} md={6} className="mb-4 mb-md-0">
            <Card
              className="h-100 border-white text-center pt-3"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <Card.Header className="border-bottom border-white pt-4">
                <h5 className="fs-1 fw-light">
                  <small className="mb-3 d-block fs-5">Monthly Subscription</small>
                  <span className="d-block fs-6 fw-light">CA$</span>
                  <span className="d-block fw-light" style={{ fontSize: 52 }}>
                    3.99
                  </span>
                  <span className="d-block mb-2 fs-6">Every month</span>
                  <span className="d-block fs-6">2 day free trial</span>
                </h5>
              </Card.Header>
              <Card.Body className="pt-4">
                <ul
                  style={{ listStyle: "none", paddingLeft: 0, fontSize: "0.75em" }}
                  className="fw-light"
                >
                  <li className="mb-2">√ Unlimited access to entire video library</li>
                  <li className="mb-2">
                    √ NEW under-represented Queer Cinema each month
                  </li>
                  <li className="mb-2">√ 75 SHOTS POCKET CINEMA Premieres</li>
                  <li className="mb-2">
                    √ Invite your friends to join & they will get one month FREE
                  </li>
                  <li className="mb-2">√ No commitments, cancel anytime.</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card
              className="h-100 border-white text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <div className="text-center">
                <Button
                  as="a"
                  href="https://www.cinemafeast.com/boxoffice"
                  target="_blank"
                  rel="noreferrer noopener"
                  variant="warning"
                  className="text-white"
                  style={{ marginTop: "-1.8rem" }}
                >
                  Best Value
                </Button>
              </div>
              <Card.Header className="border-bottom border-white pt-3">
                <h5 className="fs-1 fw-light">
                  <small className="mb-3 d-block fs-5">Yearly Subscription</small>
                  <span className="d-block fs-6 fw-light">CA$</span>
                  <span className="d-block fw-light" style={{ fontSize: 52 }}>
                    39.99
                  </span>
                  <span className="d-block mb-2 fs-6">Every year</span>
                  <span className="d-block fs-6">2 day free trial</span>
                </h5>
              </Card.Header>
              <Card.Body className="pt-4">
                <ul
                  style={{ listStyle: "none", paddingLeft: 0, fontSize: "0.75em" }}
                  className="fw-light"
                >
                  <li className="mb-2">√ Unlimited access to the entire video library</li>
                  <li className="mb-2">NEW under-represented Queer Cinema each month</li>
                  <li className="mb-2">√ 75 SHOTS POCKET CINEMA Premieres</li>
                  <li className="mb-2">
                    √ Invite your friends to join & they will get one month FREE
                  </li>
                  <li className="mb-2">√ Live streaming panels + filmmaker talks</li>
                  <li className="mb-2">√ Get 2 months extra (Value $7.98 discount)</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col className="py-2">
            <Button
              as="a"
              href="https://www.cinemafeast.com/boxoffice"
              target="_blank"
              rel="noreferrer noopener"
              variant="warning"
              size="lg"
              className="w-100 text-white"
            >
              Go to Cinema Feast
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DonateBanner
