import TermsPage from "./TermsPage"

const TermsConduct = () => (
  <TermsPage title="Code of Conduct">
    <p className="fs-4 mb-4">
      We actively enforce (and expect all members, mentors and participants to abide by
      and help enforce) policies about expected behaviour and unacceptable behaviour.
    </p>
    <section className="mb-5">
      <p>
        All <strong>community participants</strong> – including members and guests of
        members, event hosts, sponsors, presenters, exhibitors, participants and attendees
        – are expected to abide by this code of conduct.
      </p>
      <p>
        By participating in any 75 SHOTS event in any capacity, you agree to abide by this
        code of conduct, and cooperate with organizers and admins who enforce it.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Expected Behavior</h4>
      <p>
        We insist that everyone who uses the space remains mindful of, and takes
        responsibility for, their speech and behavior. This includes:
      </p>
      <ul>
        <li>
          <strong>Prioritizing</strong> the expression of people who are systemically
          marginalized in tech and game spaces, especially those who are
          gender-marginalized and/or racialized and upholding a safe, judgment-free,
          confidential space for that expression.
        </li>
        <li>Actively listening to others and not dominating discussions.</li>
        <li>
          Respecting physical and emotional boundaries. Always ask before touching, and
          check in before discussing topics that may be triggering.
        </li>
        <li>
          Not making assumptions about any individual's identity, experiences or pronouns.
        </li>
        <li>
          Not using words that are racist, sexist, homophobic, classist, transphobic,
          cissexist, ageist or ableist.
        </li>
        <li>
          Humbly accepting respectful correction (or correcting yourself!), keeping in
          mind that the impact of your words on other people is more important than your
          intent.
        </li>
      </ul>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Unacceptable Behavior</h4>
      <p>
        We believe that failing to address dynamics of hierarchy, power and privilege
        alienates and further victimizes our members and prevents us from creating the
        safe and equitable space we want
      </p>
      <p>
        <strong>
          We do not tolerate oppressive behavior, harassment, destructive behavior, or
          exclusionary actions.
        </strong>
      </p>
      <p>Examples of these actions include:</p>
      <p>
        <strong>Oppressive behavior:</strong> any conduct that demeans, marginalizes,
        rejects, threatens or harms anyone on the basis of identity, background, or
        ability. This includes publicly sympathizing with hate groups or endorsing
        political beliefs that contradict our values; deliberate misgendering, using
        inappropriate pronouns, or use of deadnames
      </p>
      <p>
        <strong>Harassment:</strong> deliberate intimidation; stalking; following;
        harassing photography or recording; disruption of events; aggressive, slanderous,
        derogatory, or threatening comments online or in person; repeated or sustained
        private communication after requests to cease; publication of non-harassing
        private communication (including audio or video recordings and screenshots of
        direct messages and closed chats on Slack or Google Meet); physical contact and
        simulated physical contact without consent or after a request to stop.
      </p>
      <p>
        <strong>Destructive behavior:</strong> sustained disruption of discussions online
        or off, damaging or altering any part of the building, inside or out, including
        furniture and equipment; damaging, altering or using other people's belongings,
        including personal computers and digital content or code created by other people
        (unless you have their consent).
      </p>
      <p>
        <strong>Exclusionary actions:</strong> denying certain participants opportunities
        to share views, skills and other contributions; engaging in favoritism; and
        creating or reinforcing an inequitable learning environment.
      </p>
      <p>
        If you are being – or have been at any point in the past – harassed by a member of
        the 75 SHOTS community, or someone who attends events at our space, we want to
        know about it. This includes harassment that happens outside our spaces and
        events. 75 SHOTS may choose to exclude people from events and membership based on
        their past behavior, including behavior outside our spaces and behavior towards
        people who are not part of the DMG community.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">How to Report a Violation or Discuss a Concern</h4>
      <p>
        Email <a href="mailto:75shotsmovie@gmail.com">75shotsmovie@gmail.com</a> to reach
        the current chair (or co-chairs) of the conflict resolution committee. p
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Enforcement</h4>
      <p>Anyone asked to stop unacceptable behavior is expected to comply immediately.</p>
      <p>
        This Code of Conduct extends to both in-person spaces and events as well as
        digital/virtual gatherings. Everyone is expected to comply with and uphold this
        code of conduct in all digital interactions, including 75 SHOTS'.
      </p>
      <p>
        Anyone who engages in sustained or repeated unacceptable behavior may be
        sanctioned or permanently expelled from the community without warning and without
        refund in the case of a paid event.
      </p>
      <p className="fst-italic">
        By registering for or hosting an event, you agree to make your name visible to
        event organizers, volunteers, admins and staff. This visibility helps maintain the
        safety of our space. Anyone not registered for an event may be denied entry at the
        discretion of the event host.
      </p>
      <p></p>
    </section>
  </TermsPage>
)

export default TermsConduct
