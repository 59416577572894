import Col from "react-bootstrap/Col"
import FormNewsletterSignUp from "../FormNewsletterSignUp"

const FooterNewsletter = ({ handleAddNewSubscription }) => (
  <Col xs={12} className="my-4">
    <FormNewsletterSignUp handleAddNewSubscription={handleAddNewSubscription} />
  </Col>
)

export default FooterNewsletter
