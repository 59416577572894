const countries = [
  { id: "none", label: "Select country...", value: "" },
  { id: "afg", label: "Afghanistan", value: "Afghanistan" },
  { id: "dza", label: "Algeria", value: "Algeria" },
  { id: "atg", label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { id: "bgd", label: "Bangladesh", value: "Bangladesh" },
  { id: "brb", label: "Barbados", value: "Barbados" },
  { id: "brn", label: "Brunei", value: "Brunei" },
  { id: "bdi", label: "Burundi", value: "Burundi" },
  { id: "cmr", label: "Cameroon", value: "Cameroon" },
  { id: "tcd", label: "Chad", value: "Chad" },
  { id: "com", label: "Comoros", value: "Comoros" },
  { id: "cok", label: "Cook Islands", value: "Cook Islands" },
  { id: "dma", label: "Dominica", value: "Dominica" },
  { id: "egy", label: "Egypt", value: "Egypt" },
  { id: "eri", label: "Eritrea", value: "Eritrea" },
  { id: "swz", label: "Eswatini", value: "Eswatini" },
  { id: "eth", label: "Ethiopia", value: "Ethiopia" },
  { id: "gmb", label: "Gambia", value: "Gambia" },
  { id: "gha", label: "Ghana", value: "Ghana" },
  { id: "grd", label: "Grenada", value: "Grenada" },
  { id: "gin", label: "Guinea", value: "Guinea" },
  { id: "guy", label: "Guyana", value: "Guyana" },
  { id: "idn", label: "Indonesia", value: "Indonesia" },
  { id: "irn", label: "Iran", value: "Iran" },
  { id: "irq", label: "Iraq", value: "Iraq" },
  { id: "jam", label: "Jamaica", value: "Jamaica" },
  { id: "ken", label: "Kenya", value: "Kenya" },
  { id: "kir", label: "Kiribati", value: "Kiribati" },
  { id: "kwt", label: "Kuwait", value: "Kuwait" },
  { id: "lbn", label: "Lebanon", value: "Lebanon" },
  { id: "lbr", label: "Liberia", value: "Liberia" },
  { id: "lby", label: "Libya", value: "Libya" },
  { id: "mwi", label: "Malawi", value: "Malawi" },
  { id: "mys", label: "Malaysia", value: "Malaysia" },
  { id: "mdv", label: "Maldives", value: "Maldives" },
  { id: "mrt", label: "Mauritania", value: "Mauritania" },
  { id: "mus", label: "Mauritius", value: "Mauritius" },
  { id: "mar", label: "Morocco", value: "Morocco" },
  { id: "mmr", label: "Myanmar", value: "Myanmar" },
  { id: "nam", label: "Namibia", value: "Namibia" },
  { id: "nga", label: "Nigeria", value: "Nigeria" },
  { id: "omn", label: "Oman", value: "Oman" },
  { id: "pak", label: "Pakistan", value: "Pakistan" },
  { id: "pse", label: "Palestine", value: "Palestine" },
  { id: "png", label: "Papua New Guinea", value: "Papua New Guinea" },
  { id: "qat", label: "Qatar", value: "Qatar" },
  { id: "kna", label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { id: "lca", label: "Saint Lucia", value: "Saint Lucia" },
  {
    id: "vct",
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { id: "wsm", label: "Samoa", value: "Samoa" },
  { id: "sau", label: "Saudi Arabia", value: "Saudi Arabia" },
  { id: "sen", label: "Senegal", value: "Senegal" },
  { id: "sle", label: "Sierra Leone", value: "Sierra Leone" },
  { id: "sgp", label: "Singapore", value: "Singapore" },
  { id: "slb", label: "Solomon Islands", value: "Solomon Islands" },
  { id: "som", label: "Somalia", value: "Somalia" },
  { id: "ssd", label: "South Sudan", value: "South Sudan" },
  { id: "lka", label: "Sri Lanka", value: "Sri Lanka" },
  { id: "sdn", label: "Sudan", value: "Sudan" },
  { id: "syr", label: "Syria", value: "Syria" },
  { id: "tza", label: "Tanzania", value: "Tanzania" },
  { id: "tgo", label: "Togo", value: "Togo" },
  { id: "ton", label: "Tonga", value: "Tonga" },
  { id: "tun", label: "Tunisia", value: "Tunisia" },
  { id: "tkm", label: "Turkmenistan", value: "Turkmenistan" },
  { id: "tuv", label: "Tuvalu", value: "Tuvalu" },
  { id: "uga", label: "Uganda", value: "Uganda" },
  { id: "are", label: "United Arab Emirates", value: "United Arab Emirates" },
  { id: "uzb", label: "Uzbekistan", value: "Uzbekistan" },
  { id: "yem", label: "Yemen", value: "Yemen" },
  { id: "zmb", label: "Zambia", value: "Zambia" },
  { id: "zwe", label: "Zimbabwe", value: "Zimbabwe" },
]

export default countries
