import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"

const BannerSectionImage = () => {
  return (
    <Col xs={12} className="p-0">
      <Image
        src="https://res.cloudinary.com/pocketcinema/image/upload/v1631994620/IMG_1251_t6wzoq.jpg"
        alt="Illustration of a broken mobile screen through which a woman's face can be seen"
        fluid
      />
    </Col>
  )
}

export default BannerSectionImage
