import TemplatePageSectionB from "../TemplatePageSectionB"

const ProductionSection = () => {
  return (
    <TemplatePageSectionB
      title="On Demand"
      subtitle1="The Cinema Feast Collection"
      imgLink="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1632095624/Screen_Shot_2021-03-19_at_8.52.48_PM_flsvyn.png"
      imgAlt="Reflection of a person filming with a mobile phone"
      pageLink="/productions"
      btnText="Watch productions"
      btnLink="/productions"
    >
      <p>
        is now available for viewing from your computer, laptop, or from any mobile
        device. Queer Cinema streaming services at affordable rates. Sign up for monthly
        or annual subscriptions, which comes with a free trial period. Support independent
        filmmakers and the work of our organization. We provide FREE subscriptions to
        people in need & 100% of the proceeds go to the 75 SHOTS POCKET CINEMA Mentorship
        Program.
      </p>
      <p>
        We create opportunities to produce and distribute under-represented LGBTQ+
        creators based in countries in the Global South where homosexuality is a crime.
        Our platform is a bridge between art and human rights, with a strong emphasis on
        content creation.
      </p>
      <p>
        Join a unique platform that showcases unsung stories and validates our heroes. We
        aim to move audiences from empathy to action on LGBTQ+ rights issues.
      </p>
    </TemplatePageSectionB>
  )
}

export default ProductionSection
