import React, { useState } from "react"
import { createNewVideo, createNewVideoAsset } from "../../services/video"
import Container from "react-bootstrap/Container"
import AddVideoForm from "./AddVideoForm"
//import AddAssetForm from "./AddAssetForm"

const AdminAddVideo = () => {
  const [loading, setLoading] = useState(false)

  // create new video asset
  const addNewAsset = async (data) => {
    try {
      const asset = await createNewVideoAsset(data)
      console.log("addNewAsset: ", asset)
      //asset && uploadVideo(data.vodAssetVideoId)
    } catch (err) {
      console.log("addNewAsset error: ", err.message)
    }
  }

  // create new video object
  const addNewVideo = async (data) => {
    try {
      const id = await createNewVideo(data.vodAssetVideoId)
      console.log("addNewVideo: ", id)
      id && addNewAsset(data)
    } catch (err) {
      console.log("addNewVideo error: ", err.message)
    }
  }

  return (
    <Container as="section">
      <div className="mb-2 pt-4 text-center">
        <h3>Add Video</h3>
      </div>
      <AddVideoForm
        handleAddNewVideo={addNewVideo}
        handleLoading={setLoading}
        loading={loading}
      />
      {/*<AddAssetForm />*/}
    </Container>
  )
}

export default AdminAddVideo
