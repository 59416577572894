import { Link } from "react-router-dom"
import ImgLogo from "../../media/75_shots_logo_bw.png"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Image from "react-bootstrap/Image"
import HeaderNav from "./HeaderNav"
import HeaderNavUser from "./HeaderNavUser"
//import HeaderNavSignIn from "./HeaderNavSignIn"
import DonateButton from "./DonateButton"
import HeaderSocial from "./HeaderSocial"

const Header = ({ user, adminUser }) => {
  return (
    <Container as="header" fluid="xl">
      <Navbar collapseOnSelect expand="md" fixed="top" className="bg-white p-3">
        <Navbar.Brand as={Link} to="/" style={{ width: 32 }}>
          <Image src={ImgLogo} alt="75 SHOTS" fluid />
        </Navbar.Brand>
        <div className="d-md-none ms-auto px-2">
          <DonateButton />
        </div>
        <Navbar.Toggle aria-controls="header-nav" />

        <Navbar.Collapse id="header-nav">
          <HeaderNav adminUser={adminUser} />
          <div className="d-none d-md-block px-2">
            <DonateButton size="lg" />
          </div>
          <HeaderSocial />
          {user && <HeaderNavUser user={user} />}
          {/*user ? <HeaderNavUser user={user} /> : <HeaderNavSignIn />*/}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
