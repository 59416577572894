import TermsPage from "./TermsPage"

const TermsPrivacy = () => (
  <TermsPage title="75 SHOTS Privacy Policy">
    <p className="fs-4 mb-3">
      We at 75 SHOTS have created this Privacy Policy because we know you care about how
      information you provide to us is used and shared. This Privacy Policy describes how
      we gather information from you when you visit the 75 SHOTS website located at
      www.75shots.com and its affiliated websites (collectively referred to as the
      “Site”), and how we may use and share that information. By visiting our Site, you
      are accepting the practices described in this Privacy Policy and the accompanying
      Terms of Service.
    </p>
    <p className="mb-5">
      In this Privacy Policy, the words “we,” “us” and “our” refer to 75 SHOTS.
    </p>
    <section className="mb-5">
      <h4 className="mb-4">Collected Information and Use</h4>
      <p>
        If you desire to have access to certain restricted sections of the Site, you will
        be required to become a registered user, and to submit certain personally
        identifiable information to 75 SHOTS (“Personally Identifiable Information”).
        Personally Identifiable Information may include your name, email address,
        password, and your location. Your name may be publicly displayed on the Site if
        you engage with certain features, such as registering for an event, publishing a
        project, or writing a comment or blog post. Your password and email address will
        never be publicly displayed
      </p>
      <p>
        You may enter additional content including your username, biography, skills,
        photos, profile image, questions, projects, verbs, or other content or submissions
        (collectively referred to as the “User Content”). User Content may be viewable by
        the public. It is your responsibility to ensure that you do not submit any User
        Content that you do not want publicly displayed, and that nothing you submit
        includes proprietary information, information that’s illegal to make public, or
        personal information about you such as your social insurance number.
      </p>
      <p>
        We use the Personally Identifiable Information you provide in a manner that is
        consistent with this Privacy Policy. For instance, we will use the Personally
        Identifiable Information you input during the registration process to administer
        access to the Site. We also use your Personally Identifiable Information and other
        non-personally identifiable information collected through the Site to help us
        improve the content and functionality of the Site and to better understand the
        users of our Site. In addition, we may use your Personally Identifiable
        Information to update you about the Site. If you decide at any time that you no
        longer wish to receive such information or communications from us, please follow
        the unsubscribe instructions provided in any of the communications.
      </p>
      <p>
        75 SHOTS, or a third party service provider that does not have access to your
        Personally Identifiable Information, may collect statistics about the behavior of
        visitors to the Site. Our servers may automatically record information that your
        browser sends whenever you visit a DMG website. These server logs may include
        information such as the page(s) you visited, your Internet Protocol address,
        cookie information, web browser type, browser language, and the date and time of
        your request. DMG uses this traffic data to help diagnose problems with its
        server(s), analyze trends and administer the Site.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Cookies</h4>
      <p>
        Like many websites, we use “cookie” technology to collect additional website usage
        data and to improve the Site and our service. A cookie is a small data file that
        we may transfer to your computer’s hard disk. We do not use cookies to collect
        Personally Identifiable Information. DMG may use session cookies to improve the
        Site and our service, for example, to keep you logged in to your user account for
        a number of days.
      </p>
      <p>
        You can set your web browser to refuse all cookies, but some 75 SHOTS features and
        services may not function properly if your cookies are disabled.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Sharing your Information</h4>
      <p>
        75 SHOTS is not in the business of selling your information. We consider this
        information to be a vital part of our relationship with you. There are, however,
        certain circumstances in which we may share your Personally Identifiable
        Information with certain third parties including those set forth below:
      </p>
      <h5>Agents and Related Third Parties</h5>
      <p>
        75 SHOTS sometimes uses other companies to perform certain business-related
        functions. For example, we may pass your Personally Identifiable Information to
        our designated service provider(s) in order to process payments. When we employ
        another company to perform a function of this nature, we provide them only with
        the information that they need to perform their specific function.
      </p>
      <h5>Legal Requirements</h5>
      <p>
        75 SHOTS may disclose your Personally Identifiable Information if required to do
        so by law or in the good faith belief that such action is necessary to (a) comply
        with a legal obligation, (b) protect and defend the rights or property of 75
        SHOTS, (c) act in urgent circumstances to protect the personal safety of users of
        the Site or the public, or (d) protect against legal liability
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">
        Accessing and Modifying Personally Identifiable Information and Communication
        Preferences
      </h4>
      <p>
        You may access, review, and/or make changes to Personally Identifiable Information
        that you have provided to us by logging in on the Site, clicking on "Profile." In
        addition, you may manage your receipt of non-transactional communications by
        clicking on the “unsubscribe” link located on the bottom of any 75 SHOTS marketing
        e-mails. You cannot opt-out of receiving transactional e-mails related to your
        account with 75 SHOTS.
      </p>
      <p></p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Children</h4>
      <p>
        75 SHOTS is not directed at individuals under thirteen years of age, the Site does
        not knowingly accept registrations from children under thirteen years of age, and
        we do not intend to collect any Personally Identifiable Information from such
        individuals. We recommend that minors over the age of thirteen ask their parents
        for permission before sending any information about themselves to anyone over the
        Internet.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Your Acceptance of this Privacy Policy</h4>
      <p>
        By using the Site, you signify your assent to this Privacy Policy. If you do not
        agree with this Privacy Policy, please do not use the Site. Your continued use of
        the Site following the posting of changes to these terms will mean you accept
        those changes.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Credit Card Security</h4>
      <p>
        75 SHOTS does not store credit card information. Any credit card transactions are
        transmitted to a secure financial gateway, and we endeavor to protect the security
        of your payment information during transmission by using Secure Sockets Layer
        (SSL) technology. Credit card information is stored in an encrypted form by
        PayPal.com, our payment gateway provider. However, we are unable to guarantee that
        the security measures we take will not be penetrated or compromised or that your
        data will remain secure under all circumstances.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Confidentiality</h4>
      <p>
        75 SHOTS is very concerned about safeguarding the confidentiality of your
        Personally Identifiable Information. You, too, need to strive to protect against
        unauthorized access to your password and to your computer by signing off once you
        have finished using a shared computer.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Changes to this Privacy Policy</h4>
      <p>
        We may update this policy at any time by simply posting such modification on the
        Site. You are advised to consult this Privacy Policy regularly for any changes.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Conditions of Use</h4>
      <p>
        If you decide to visit the Site, your visit and any possible dispute over privacy
        is subject to this privacy policy and our Terms of Service.
      </p>
    </section>
    <section className="mb-5">
      <h4 className="mb-4">Links to Other Sites</h4>
      <p>
        75shots.com has links to other websites. Once you follow a link to somewhere else,
        your actions are no longer subject to this Privacy Policy.
      </p>
    </section>
  </TermsPage>
)

export default TermsPrivacy
