import React, { useEffect, useReducer, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Hub } from "aws-amplify/utils"
import { currentAuthenticatedUser } from "./services/auth"
import {
  getNewsletterSubscriptions,
  createNewSubscription,
  removeSubscription,
} from "./services/subscribe"
import authReducer from "./reducers/authReducer"
import newsletterSubscriptionReducer from "./reducers/newsletterSubscriptionReducer"
import "./App.scss"
import Row from "react-bootstrap/Row"
import NewsletterModal from "./components/NewsletterModal"
import Routes from "./components/Routes"
import Footer from "./components/Footer"

const App = () => {
  const [state1, dispatch1] = useReducer(authReducer, { user: undefined })
  const [state2, dispatch2] = useReducer(newsletterSubscriptionReducer, {
    subscriptions: [],
  })
  const [adminUser, setAdminUser] = useState(false)
  const [subscriberToDelete, setSubscriberToDelete] = useState(null)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    // listen to auth events
    // and change user state accordingly
    const setAuthListener = () => {
      Hub.listen("auth", ({ payload }) => {
        switch (payload.event) {
          case "signIn":
            if (!state1.user) {
              dispatch1({ type: "SIGNIN USER", payload: payload.data })
            }
            break
          case "signOut":
            if (state1.user) {
              dispatch1({ type: "SIGNOUT USER" })
            }
            break
          default:
            break
        }
      })
    }

    // check if a user is logged in when the page is loaded
    const checkUser = async () => {
      try {
        const currentUser = await currentAuthenticatedUser()

        if (currentUser) {
          !state1.user && dispatch1({ type: "SIGNIN USER", payload: currentUser })
          // TODO: set up admin check
          setAdminUser(true)
        }
      } catch (err) {
        console.log("user fetch error", err.message)
      }
    }

    setAuthListener()
    checkUser()

    // TODO: stop listening to auth events on unmount
  }, [state1.user])

  // fetch subscriptions from server
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getNewsletterSubscriptions()

        if (data) {
          dispatch2({ type: "SET NEWSLETTER SUBSCRIPTIONS", payload: data })
        }
      } catch (err) {
        //TODO: error message
      }
    }

    getData()
  }, [])

  const showNotification = (text) => {
    setMessage(text)
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }

  const addNewSubscription = async (data) => {
    // check that email has not been added already
    const hasEmail = state2.subscriptions.find((item) => item.email === data.email)

    if (!hasEmail) {
      try {
        const response = await createNewSubscription(data)

        if (response) {
          dispatch2({ type: "CREATE NEWSLETTER SUBSCRIPTION", payload: response })
          showNotification("Thank you for subscribing to our newsletter!")
        }
      } catch (err) {
        showNotification(
          "Your newsletter subscription was unsuccessful. Please try again."
        )
      }
    }

    hasEmail && showNotification("You are already a subscriber!")
  }

  const deleteSubscription = async (id) => {
    try {
      const response = await removeSubscription(id)

      if (response) {
        // update local state
        setSubscriberToDelete(null)
        dispatch2({ type: "DELETE NEWSLETTER SUBSCRIPTION", payload: id })
      }
    } catch (err) {
      //TODO: error message
    }
  }

  return (
    <Router>
      <Row className="vh-100 align-items-start">
        <NewsletterModal show={show} message={message} />
        <Routes
          user={state1.user}
          adminUser={adminUser}
          subscriptions={state2.subscriptions}
          subscriberToDelete={subscriberToDelete}
          setSubscriberToDelete={setSubscriberToDelete}
          handleDeleteSubscription={deleteSubscription}
        />
        <Footer handleAddNewSubscription={addNewSubscription} />
      </Row>
    </Router>
  )
}

export default App
