const videoReducer = (state, action) => {
  switch (action.type) {
    case "SET VIDEOS":
      return { videos: action.payload }
    case "UPDATE VIDEO":
      const id = action.payload.id
      return {
        videos: state.videos.map((item) => (item.id !== id ? item : action.payload)),
      }
    case "DELETE VIDEO":
      return { videos: state.videos.filter((item) => item.id !== action.payload) }
    default:
      return state
  }
}

export default videoReducer
