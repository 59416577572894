import { Switch, Route } from "react-router-dom"
import Admin from "./Admin"
import Main from "./Main"
import AdminSignIn from "../AdminSignIn"
import AdminDashboard from "../AdminDashboard"
import AdminApplications from "../AdminApplications"
import AdminDonations from "../AdminDonations"
import AdminNewsletterSubscriptions from "../AdminNewsletterSubscriptions"
import AdminVideos from "../AdminVideos"
import TermsConduct from "../Terms/TermsConduct"
import TermsPrivacy from "../Terms/TermsPrivacy"
import Donate from "../Donate"
import Productions from "../Productions"
//import SignUp from "../SignUp"
//import SignIn from "../SignIn"
import PocketCinema from "../PocketCinema"
import Application from "../Application"
import Training from "../Training"
import About from "../About"
import Home from "../Home"

const Routes = ({
  user,
  adminUser,
  subscriptions,
  subscriberToDelete,
  setSubscriberToDelete,
  handleDeleteSubscription,
}) => (
  <Switch>
    {/** ADMIN */}
    <Route path="/admin">
      {user ? (
        adminUser ? (
          <Admin user={user}>
            <AdminDashboard subscriptions={subscriptions} />
          </Admin>
        ) : (
          <Main user={user}>
            <Home />
          </Main>
        )
      ) : (
        <Main user={user}>
          <AdminSignIn />
        </Main>
      )}
    </Route>
    <Route path="/admin-applications">
      {user ? (
        adminUser ? (
          <Admin user={user}>
            <AdminApplications />
          </Admin>
        ) : (
          <Main user={user}>
            <Home />
          </Main>
        )
      ) : (
        <Main user={user}>
          <AdminSignIn />
        </Main>
      )}
    </Route>
    <Route path="/admin-donations">
      {user ? (
        adminUser ? (
          <Admin user={user}>
            <AdminDonations />
          </Admin>
        ) : (
          <Main user={user}>
            <Home />
          </Main>
        )
      ) : (
        <Main user={user}>
          <AdminSignIn />
        </Main>
      )}
    </Route>
    <Route path="/admin-subscriptions">
      {user ? (
        adminUser ? (
          <Admin user={user}>
            <AdminNewsletterSubscriptions
              subscriptions={subscriptions}
              subscriberToDelete={subscriberToDelete}
              setSubscriberToDelete={setSubscriberToDelete}
              handleDeleteSubscription={handleDeleteSubscription}
            />
          </Admin>
        ) : (
          <Main user={user}>
            <Home />
          </Main>
        )
      ) : (
        <Main user={user}>
          <AdminSignIn />
        </Main>
      )}
    </Route>
    <Route path="/admin-videos">
      {user ? (
        adminUser ? (
          <Admin user={user}>
            <AdminVideos />
          </Admin>
        ) : (
          <Main user={user}>
            <Home />
          </Main>
        )
      ) : (
        <Main user={user}>
          <AdminSignIn />
        </Main>
      )}
    </Route>
    {/** SIGN UP / SIGN IN */}
    {/*<Route path="/signup">
      <Main user={user}>
        <SignUp />
      </Main>
    </Route>
    <Route path="/signin">
      <Main user={user}>
        <SignIn />
      </Main>
    </Route>*/}
    {/** MAIN */}
    <Route path="/code-of-conduct">
      <Main user={user} adminUser={adminUser}>
        <TermsConduct />
      </Main>
    </Route>
    <Route path="/privacy-policy">
      <Main user={user} adminUser={adminUser}>
        <TermsPrivacy />
      </Main>
    </Route>
    <Route path="/donate">
      <Main user={user} adminUser={adminUser}>
        <Donate />
      </Main>
    </Route>
    <Route path="/productions">
      <Main user={user} adminUser={adminUser}>
        <Productions />
      </Main>
    </Route>
    <Route exact path="/training/pocket-cinema">
      <Main user={user} adminUser={adminUser}>
        <PocketCinema />
      </Main>
    </Route>
    <Route exact path="/training/application-form">
      <Main user={user} adminUser={adminUser}>
        <Application />
      </Main>
    </Route>
    <Route exact path="/training">
      <Main user={user} adminUser={adminUser}>
        <Training />
      </Main>
    </Route>
    <Route path="/about">
      <Main user={user} adminUser={adminUser}>
        <About />
      </Main>
    </Route>
    <Route exact path="/">
      <Main user={user} adminUser={adminUser}>
        <Home />
      </Main>
    </Route>
  </Switch>
)

export default Routes
