import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import FooterNewsletter from "./FooterNewsletter"
import FooterLogo from "./FooterLogo"
import FooterSocial from "./FooterSocial"
import FooterTerms from "./FooterTerms"

const Footer = ({ handleAddNewSubscription }) => (
  <Container
    as="footer"
    fluid="xl"
    className="align-self-end"
    style={{ backgroundColor: "#000" }}
  >
    <Row className="g-2 align-items-center">
      <FooterNewsletter handleAddNewSubscription={handleAddNewSubscription} />
      <FooterLogo />
      <FooterSocial />
      <FooterTerms />
    </Row>
  </Container>
)

export default Footer
