import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ApplicationDeleteModal = ({ show, handleClose, handleDeleteApplication }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Deletion</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center">
      Are you sure you want to delete this application?
      <Row className="pt-4 g-2">
        <Col xs={6}>
          <Button
            variant="danger"
            className="w-100"
            onClick={() => handleDeleteApplication()}
          >
            Delete
          </Button>
        </Col>
        <Col xs={6}>
          <Button variant="outline-secondary" className="w-100" onClick={handleClose}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
)

export default ApplicationDeleteModal
