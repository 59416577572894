import Container from "react-bootstrap/Container"
//import MissionSection from "./MissionSection"
import BannerSection from "./BannerSection"
import QuoteSection from "./QuoteSection"
import PayPerViewAside from "./PayPerViewAside"
import ProductionSection from "./ProductionSection"
import UnderTheRadarSection from "./UnderTheRadarSection"
import TrainingSection from "./TrainingSection"
import VideoSection from "./VideoSection"

const Home = () => (
  <Container as="main" fluid="xl" className="mt-72">
    <BannerSection />
    {/*<MissionSection />*/}
    <QuoteSection />
    <PayPerViewAside />
    <div className="py-5">
      <TrainingSection />
      <ProductionSection />
      <UnderTheRadarSection />
    </div>
    <VideoSection />
  </Container>
)

export default Home
