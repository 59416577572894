import { ArrowRight } from "react-bootstrap-icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
//import TemplateImageTransition from "./TemplateImageTransition"

const TemplatePageSectionC = ({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  imgLink,
  imgAlt,
  pageLink,
  btnText,
  btnLink,
  children,
}) => {
  return (
    <Row className="align-items-center">
      <Col
        xs={{ span: 10, offset: 1, order: "last" }}
        md={{ span: 4, offset: 1, order: "first" }}
        className="py-5"
      >
        <h3 className="mb-4 fs-2 lh-1 text-uppercase">
          <span className="fw-lighter">{title} </span>
          <br />
          <span className="fw-bolder">{subtitle1}</span>
          <br />
          <span className="fw-bolder">{subtitle2}</span>
          <br />
          <span className="fw-bolder">{subtitle3}</span>
        </h3>
        {children}
        <a
          href={pageLink}
          variant="link"
          className="p-0 text-decoration-none text-uppercase fw-light"
        >
          <ArrowRight size={18} className="mb-1" /> {btnText}
        </a>
      </Col>
      <Col
        xs={{ span: 10, offset: 1, order: "first" }}
        md={{ span: 5, offset: 1, order: "last" }}
      >
        <a href={pageLink}>
          <Image src={imgLink} alt={imgAlt} fluid roundedCircle />
        </a>
      </Col>
    </Row>
  )
}

export default TemplatePageSectionC
