const authReducer = (state, action) => {
  switch (action.type) {
    case "SIGNIN USER":
      return { user: action.payload }
    case "SIGNOUT USER":
      return { user: undefined }
    default:
      return state
  }
}

export default authReducer
