import Row from "react-bootstrap/Row"
import BannerSectionText from "./BannerSectionText"
import BannerSectionImage from "./BannerSectionImage"

const BannerSection = () => {
  return (
    <Row className="bg-banner">
      <BannerSectionImage />
      <BannerSectionText />
    </Row>
  )
}

export default BannerSection
