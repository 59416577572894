import TemplatePageSectionD from "../TemplatePageSectionD"

const UnderTheRadarSection = () => (
  <TemplatePageSectionD
    title="Under The Radar"
    subtitle1="Queering Public Spaces"
    subtitle2="With Cinema From"
    subtitle3="The Global South"
    imgLink="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1676904754/under_the_radar_zonmuj.jpg"
    imgAlt="Students getting their certificate"
    pageLink="https://www.cinemafeast.com/undertheradar"
    btnText="Learn more"
    btnLink="https://www.cinemafeast.com/undertheradar"
  >
    <p>
      Under the Radar is an intimate public screening event, highlighting the struggles
      and triumphs of a population historically suppressed, disenfranchised, and often
      overlooked by mainstream media. A series of queer short films of all genres made by
      2SLGBTQ+ filmmakers from the Global South and the Diaspora pops up at dusk in
      undisclosed areas reclaiming our place in society.
    </p>
    <p>
      We aim to use art to open dialogue and build positive relationships. All films are
      in English or French with English Subtitles. Guest Filmmakers in Attendance.
      Free-of-Charge.
    </p>
  </TemplatePageSectionD>
)

export default UnderTheRadarSection
