//import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
//import Card from "react-bootstrap/Card"
//import { PlayCircle } from "react-bootstrap-icons"
//import videoImg from "../../media/img_75shots_4.jpg"
import PocketCinemaIntro from "./PocketCinemaIntro"

const PocketCinema = () => {
  //const [play, setPlay] = useState(false)

  return (
    <Container className="mt-72">
      {/*<Row>
        <Col xs={12}>
          <Card className="bg-dark text-white border-0">
            <Card.Img src={videoImg} alt="" style={{ borderRadius: 0 }} />
            <Card.ImgOverlay style={{ background: "rgba(0, 0, 0, 0.4)" }}>
              <Row className="h-100 align-items-center">
                <Col xs={12} className="text-center">
                  <Card.Title>
                    <Button
                      variant="ligth"
                      className="text-white"
                      style={{ marginTop: -15 }}
                      onClick={() => setPlay(true)}
                    >
                      <PlayCircle size={42} />
                    </Button>
                    <span className="fs-2 fw-bolder">Watch Karin's message</span>
                  </Card.Title>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Card>
          {play && (
            <video width="100%" height="auto" crossorigin>
              <source
                src="https://res.cloudinary.com/pocketcinema/video/upload/v1616865319/pass_the_hat_water_hu4kbj.mp4"
                type="video/mp4"
              />
              <track kind="captions" />
            </video>
          )}
        </Col>
      </Row>*/}
      <PocketCinemaIntro />
      <Row className="bg-primary subpage-header">
        <Col
          xs={12}
          md={{ span: 6, offset: 5 }}
          className="bg-primary subpage-header-content"
        >
          <figure className="text-start">
            <blockquote className="pb-3 blockquote">
              <p className="fs-2 lh-1 fw-bolder text-white">
                "Karin and 75 Shots is the thing that reminds me that I have a dream, that
                I deserve to be happy, that I can make things happen."
              </p>
            </blockquote>
            <figcaption className="text-end fs-4 fw-bold text-white">
              <cite title="Source Title">Khaled, 75 Shots Graduate</cite>
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="py-5">
        <Col xs={{ span: 8, offset: 2 }} className="text-secondary">
          <h3 className="mb-3 fs-2 text-primary">How to Apply</h3>
          <div className="mb-4">
            <p>
              Complete and send{" "}
              <Link to="/training/application-form">application form</Link> and
              introductory video of yourself to:{" "}
              <a href="mailto:75shotsmovie@gmail.com">75shotsmovie@gmail.com</a>.
            </p>
          </div>
          <div className="mb-4">
            <h4>The course includes:</h4>
            <ul>
              <li>
                How to be resourceful with the devices and technology you have on hand,
                DIY studios & gear
              </li>
              <li>Learning basic techniques of sound, lights, camera and action</li>
              <li>Exploring the different genres of cinema</li>
              <li>Finding your distinctive artistic voice</li>
              <li>Writing voice overs, synopsis & bios</li>
              <li>Discovering useful free apps and other resources</li>
            </ul>
          </div>
          <div>
            <h4>Helpful Tools</h4>
            <p>
              These are useful tools to have in order to participate but are{" "}
              <span className="fw-bold">not mandatory</span>.
            </p>
            <ul>
              <li>Smartphone: Android or iPhone</li>
              <li>Computer</li>
              <li>Apps: Filmic Pro, Open Camera, iMovie</li>
              <li>Access to WiFi</li>
              <li>Earphones with microphone</li>
              <li>Selfie stick, tripod and/or gimbal stabilizers</li>
              <li>Lenses</li>
              <li>Lights</li>
              <li>External hard drive </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PocketCinema
