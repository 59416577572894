import { XLg } from "react-bootstrap-icons"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const NewsletterSubscriptionList = ({ subscriptions, setSubscriberToDelete }) => {
  //const setDate = (date) => date.split("T")[0]

  const sortSubscriptionsByDate = (arr) => {
    return arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }

  return (
    <ListGroup as="ul" variant="flush">
      {sortSubscriptionsByDate(subscriptions).map((subscription, index) => (
        <ListGroup.Item as="li" key={index}>
          <Row className="align-items-center">
            <Col className="col-auto me-auto">
              {subscription.firstname && subscription.lastname ? (
                <span>
                  {subscription.lastname}, {subscription.firstname}
                </span>
              ) : (
                <span>-</span>
              )}
            </Col>
            <Col className="col-auto">
              {subscription.email}{" "}
              <Button
                variant="outline-danger"
                onClick={() => setSubscriberToDelete(subscription)}
              >
                <XLg size={18} className="mb-1" />
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}

export default NewsletterSubscriptionList
