import Col from "react-bootstrap/Col"

const TrainingIntro = () => {
  return (
    <Col xs={{ span: 10, offset: 1 }} className="my-5">
      <h4 className="pb-2 fs-5 text-uppercase">Training</h4>
      <div className="pt-4 pt-md-1">
        <p>
          Pocket Cinema Smartphone Filmmaking Training encourages the professional and
          artistic development of LGBTQ+ individuals living in countries with homophobic
          laws. Participants develop their imagination, creativity, self-esteem and
          employability skills. They will learn basic filmmaking skills, storytelling
          methodologies, and how to become more resourceful with the powerful tool they
          have in their pockets by transforming it into a professional movie camera.
        </p>
      </div>
    </Col>
  )
}

export default TrainingIntro
