import Accordion from "react-bootstrap/Accordion"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const DonationListItem = ({ index, item }) => {
  const calcTotalDonations = (arr) => {
    const total = arr.reduce((a, b) => a + parseInt(b.amount), 0)
    return total.toFixed(2)
  }

  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>{item.email_address}</Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col xs={7}>{item.name}</Col>
          <Col xs={5} className="text-end">
            <ul style={{ listStyle: "none" }}>
              {item.donations.map((donation, index) => (
                <li key={index}>
                  {donation.amount} {donation.currency}
                </li>
              ))}
              <li key="total" className="border-top">
                <strong>Total: {calcTotalDonations(item.donations)} CAD</strong>
              </li>
            </ul>
            {item.amount} {item.currency}
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default DonationListItem
