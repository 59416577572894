import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import NewsletterSubscriptionList from "./NewsletterSubscriptionList"
import SubscriptionDeletePopup from "./SubscriptionDeletePopup"

const AdminNewsletterSubscriptions = ({
  subscriptions,
  subscriberToDelete,
  setSubscriberToDelete,
  handleDeleteSubscription,
}) => (
  <Container as="section" className="mb-5">
    {subscriberToDelete && (
      <SubscriptionDeletePopup
        show={subscriberToDelete}
        id={subscriberToDelete.id}
        email={subscriberToDelete.email}
        handleClose={() => setSubscriberToDelete(null)}
        handleSubmit={handleDeleteSubscription}
      />
    )}
    <Row className="g-0">
      <Col xs={12} className="mb-3 text-center">
        <h3 className="mb-3">Newsletter Subscriptions</h3>
      </Col>
      <Col xs={12} mdm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        {subscriptions.length > 0 ? (
          <NewsletterSubscriptionList
            subscriptions={subscriptions}
            setSubscriberToDelete={setSubscriberToDelete}
          />
        ) : (
          <p>No subscriptions</p>
        )}
      </Col>
    </Row>
  </Container>
)

export default AdminNewsletterSubscriptions
