import React, { useEffect, useState } from "react"
import { downloadFile } from "../../storage/imageStorage"
import { /* deleteVideo, deleteVideoAsset*/ updateVideoAsset } from "../../services/video"
import Accordion from "react-bootstrap/Accordion"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import VideoPlayer from "../VideoPlayer"
import VideoListItemForm from "./VideoListItemForm"
import DefaultPoster from "../../media/img_default_video_poster_sm.png"

const VideoListItem = ({ item, index, handleUpdateVideoList, handleDeleteVideo }) => {
  const [image, setImage] = useState("")

  // fetch poster image file form S3 Storage bucket
  useEffect(() => {
    const getFile = async (poster) => {
      try {
        const file = await downloadFile(`images/${poster}`, "public")
        file && setImage(file)
      } catch (err) {
        //TODO: error message
      }
    }

    item.poster && getFile(item.poster)
  }, [item.poster])

  // update video and local state
  const updateItem = async (data) => {
    const changedItem = { id: item.id, ...data }

    try {
      const response = await updateVideoAsset(changedItem)

      if (response) {
        handleUpdateVideoList(response)
      }
    } catch (err) {
      //TODO: error handling
    }
  }

  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>{item.title}</Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col xs={12} md={5} lg={4}>
            <VideoPlayer
              src={`https://productions-staging-output-6tlstdtw.s3.eu-west-2.amazonaws.com/public/${item.id}/${item.id}.m3u8`}
            />
          </Col>
          <Col xs={12} md={7} lg={8}>
            <VideoListItemForm
              item={item}
              image={image ? image : DefaultPoster}
              handleUpdateItem={updateItem}
              handleDeleteVideo={handleDeleteVideo}
            />
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default VideoListItem
