import { generateClient } from "aws-amplify/api"
/* queries and mutations */
import {
  listVodAssets as ListVodAssets,
  getVodAsset as GetVodAsset,
  listVideoObjects as ListVideoObjects,
} from "../graphql/queries"
import {
  createVodAsset as CreateVodAsset,
  createVideoObject as CreateVideoObject,
  deleteVideoObject as DeleteVideoObject,
  deleteVodAsset as DeleteVodAsset,
  updateVodAsset as UpdateVodAsset,
} from "../graphql/mutations"
import { currentAuthenticatedUser } from "./auth"

const client = generateClient()

const isGuest = async () => {
  try {
    const response = await currentAuthenticatedUser()
    if (response === undefined) {
      return true
    } else {
      return false
    }
  } catch (err) {
    console.log("isGuest error: ", err)
  }
}

export const getVideos = async () => {
  const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: ListVodAssets,
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    // returns a list of video assets
    return result.data.listVodAssets.items ?? []
  } catch (err) {
    console.log("getVideos error: ", err)
  }
}

export const getVideo = async (id) => {
  const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: GetVodAsset,
      variables: { id },
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    // returns a list of videos
    return result.data.getVodAsset ?? null
  } catch (err) {
    console.log("getVideo error: ", err)
  }
}

export const getVideoObjects = async () => {
  const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: ListVideoObjects,
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    // returns a list of video assets
    return result.data.listVideoObjects.items
  } catch (err) {
    console.log("getVideos error: ", err)
  }
}

// id
export const createNewVideo = async (id) => {
  try {
    const result = await client.graphql({
      query: CreateVideoObject,
      variables: {
        input: { id },
      },
    })
    // get id
    return result.data.createVideoObject.id
  } catch (err) {
    console.log("createNewVideoObject error", err)
  }
}

// data = { title: "", description: "", poster: "", vodAssetVideoId: "" }
export const createNewVideoAsset = async (data) => {
  try {
    const result = await client.graphql({
      query: CreateVodAsset,
      variables: {
        input: data,
      },
    })
    // get newly created video
    return result.data.createVodAsset
  } catch (err) {
    console.log("createNewVideoAsset error", err)
  }
}

export const deleteVideo = async (id) => {
  try {
    const result = await client.graphql({
      query: DeleteVideoObject,
      variables: {
        input: { id },
      },
    })
    // get deleted video
    return result.data.deleteVideoObject
  } catch (err) {
    console.log("deleteVideoObject error", err)
  }
}

export const deleteVideoAsset = async (id) => {
  try {
    const result = await client.graphql({
      query: DeleteVodAsset,
      variables: {
        input: { id },
      },
    })
    // get deleted asset
    return result.data.deleteVodAsset
  } catch (err) {
    console.log("deleteVideoAsset error", err)
  }
}

export const updateVideoAsset = async (data) => {
  try {
    const result = await client.graphql({
      query: UpdateVodAsset,
      variables: {
        input: data,
      },
    })
    // get updated asset
    return result.data.updateVodAsset
  } catch (err) {
    console.log("updateVideoAsset error", err)
  }
}
