import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const TermsPage = ({ title, children }) => (
  <Container as="main" fluid="xl" className="mt-72">
    <Row as="article" className="mx-3 my-5">
      <Col xs={12} className="text-center">
        <h3 className="mb-4">{title}</h3>
      </Col>
      <Col xs={12} md={{ span: 8, offset: 2 }}>
        {children}
      </Col>
    </Row>
  </Container>
)

export default TermsPage
