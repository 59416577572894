import { Link } from "react-router-dom"
import ImgLogo from "../../media/75_shots_logo_bw.png"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Image from "react-bootstrap/Image"
import AdminHeaderNav from "./AdminHeaderNav"
import AdminHeaderNavUser from "./AdminHeaderNavUser"

const AdminHeader = ({ user }) => {
  return (
    <Container as="header" fluid="xl">
      <Navbar collapseOnSelect expand="md" fixed="top" className="bg-white p-3">
        <Navbar.Brand as={Link} to="/" style={{ width: 32 }}>
          <Image src={ImgLogo} alt="75 SHOTS" fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="admin-header-nav" />

        <Navbar.Collapse id="admin-header-nav">
          <AdminHeaderNav />
          {user && <AdminHeaderNavUser user={user} />}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default AdminHeader
