import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"

// Storybook example: https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default
// PayPal SDK: https://developer.paypal.com/sdk/js/reference/#createorder
const DonatePayPalButton = ({ handleAddNewDonation }) => {
  const [{ isPending, isRejected }] = usePayPalScriptReducer()
  const amount = "50"
  const currency = "CAD"
  const buttonStyle = {
    layout: "vertical",
    color: "silver",
    tagline: false,
  }

  return (
    <Col xs={12} md={{ span: 6, offset: 3 }} className="mb-4">
      {isPending ? (
        <Button variant="link" size="lg" className="w-100">
          <Spinner animation="border" variant="secondary" />
        </Button>
      ) : (
        <PayPalButtons
          style={buttonStyle}
          disabled={isRejected}
          forceReRender={[amount, currency]}
          fundingSource={undefined}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Donation to 75shots.com",
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            /*
            .then((orderId) => {
              // Your code here after create the order
              return orderId
            })
            */
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              // Save payment details to the server
              const newDonation = {
                amount: details.purchase_units[0].amount.value,
                currency: details.purchase_units[0].amount.currency_code,
                payer_id: details.payer.payer_id,
                email_address: details.payer.email_address,
                given_name: details.payer.name.given_name,
                surname: details.payer.name.surname,
              }

              handleAddNewDonation(newDonation)
            })
          }}
          onCancel={() => {
            //TODO
          }}
          onError={(err) => {
            //TODO Add error handling
            alert("Transaction was unsuccessful")
          }}
        />
      )}
      {isRejected && <p>Unable to process payments at this time.</p>}
    </Col>
  )
}

export default DonatePayPalButton
