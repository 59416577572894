import Form from "react-bootstrap/Form"
import ProgressBar from "react-bootstrap/ProgressBar"

const FormFieldVideoFile = ({
  label,
  touched,
  errors,
  handleVideoInput,
  progress,
  error,
}) => (
  <Form.Group className="mb-1 pt-3">
    <Form.Label>{label}</Form.Label>
    <ProgressBar className="my-3">
      <ProgressBar variant={error ? "danger" : "success"} now={progress} key={1} />
      {progress !== null && (
        <ProgressBar animated variant="secondary" now={100 - progress} key={2} />
      )}
    </ProgressBar>
    <Form.Control
      type="file"
      size="lg"
      accept=".mp4"
      isInvalid={touched && errors}
      onChange={(e) => handleVideoInput(e.target.files[0])}
    />
    <Form.Text className="text-muted">Must be in MP4 format</Form.Text>
  </Form.Group>
)

export default FormFieldVideoFile
