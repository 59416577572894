import Container from "react-bootstrap/Container"
import DonateBanner from "../Donate/DonateBanner"
import ProductionsHeader from "./ProductionsHeader"
//import ProductionsContent from "./ProductionsContent"

const Productions = () => (
  <Container className="mt-72 text-white" style={{ background: "rgb(0, 0, 0)" }}>
    <ProductionsHeader />
    <DonateBanner />
    {/*<ProductionsContent />*/}
  </Container>
)

export default Productions
