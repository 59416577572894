import React, { useState } from "react"
import { ArrowRight } from "react-bootstrap-icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

const OnceUponATime = () => {
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)

  const toggleText = () => {
    if (open) {
      setOpen(false)
      setClose(true)
    }

    if (close || open === false) {
      setClose(false)
      setOpen(true)
    }
  }

  return (
    <Row
      className={
        open
          ? "py-5 bg-black text-white align-items-center expanding-text-right"
          : close
          ? "py-5 bg-black text-white align-items-center shrinking-text-right"
          : "py-5 bg-black text-white align-items-center"
      }
    >
      <Col
        xs={{ span: 10, order: "last", offset: 1 }}
        md={{ span: 4, order: "first", offset: 1 }}
        className="py-5"
      >
        <h4 className="mb-4 fs-2 fw-lighter text-uppercase">Our Story</h4>
        <div className="mb-4">
          <p>
            <span className="text-primary initial-letter">I</span>n 2016, Karin Hazé
            attended her first underground Pride event in Jamaica, where she met LGBTQ+
            advocate & Grand Marshal, Bishop Christopher Senyonjo from Uganda. She learnt
            that at that time, there were 75 countries in the World that criminalized
            homosexuality, including 11 with death penalties.
          </p>
          <p>
            This injustice struck her as an extraordinary infringement of basic human
            rights. When she came back to Canada, she launched an online video platform
            placing emphasis on queer works under-represented by mainstream media.
          </p>
          {/*<p>
            <span className="text-primary initial-letter">K</span>
            arin Hazé (she/her), an independent filmmaker and curator from Montreal has
            produced seventeen films and run four international film festivals.
          </p>
          <p>
            As a teenager, Karin fulfilled her voracious appetite for cinema by setting a{" "}
            <span class="fst-italic">Guinness World Record</span> for watching the longest
            film. She represented Canada in the{" "}
            <span class="fst-italic">
              One Minutes Film Movement and <br />
              One Minutes Anthology
            </span>
            , for which she won awards and was featured in over thirty Biennales and
            Museums around the World. 
          </p>*/}
          <div className={open ? "d-block expanding-text-down" : "d-none"}>
            <p>
              75 SHOTS was born out of Karin's desire to teach mobile filmmaking to the
              people she met at Pride in Jamaica, in order for them to tell their own
              stories.
            </p>
            <p>
              Today, participants from one of the sixty-eight countries in which
              homosexuality is still criminalized, are given a platform to think
              creatively and tell their stories, for the sake of opening other people's
              eyes and minds to different perspectives. Together we develop innovative
              ideas, expand networks and encourage transformative change, through the
              power of cinema.
            </p>
          </div>
        </div>
        <Button
          variant="link"
          className="p-0 text-decoration-none text-uppercase fw-light"
          onClick={() => toggleText()}
        >
          <ArrowRight size={18} className="mb-1" /> {open ? "Read Less" : "Read More"}
        </Button>
      </Col>
      <Col
        xs={{ span: 10, order: "first", offset: 1 }}
        md={{ span: 5, order: "last", offset: 1 }}
      >
        <Image
          src="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1629382472/Screen_Shot_2019-04-10_at_10.46.02_PM_lswo3v.jpg"
          alt="Face reflected on a mobile screen"
          fluid
          roundedCircle
        />
      </Col>
    </Row>
  )
}

export default OnceUponATime
