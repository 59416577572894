/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewsletterSubscription = /* GraphQL */ `
  mutation CreateNewsletterSubscription(
    $input: CreateNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    createNewsletterSubscription(input: $input, condition: $condition) {
      id
      email
      firstname
      lastname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNewsletterSubscription = /* GraphQL */ `
  mutation UpdateNewsletterSubscription(
    $input: UpdateNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    updateNewsletterSubscription(input: $input, condition: $condition) {
      id
      email
      firstname
      lastname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNewsletterSubscription = /* GraphQL */ `
  mutation DeleteNewsletterSubscription(
    $input: DeleteNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    deleteNewsletterSubscription(input: $input, condition: $condition) {
      id
      email
      firstname
      lastname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDonation = /* GraphQL */ `
  mutation CreateDonation(
    $input: CreateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    createDonation(input: $input, condition: $condition) {
      id
      amount
      currency
      payer_id
      email_address
      given_name
      surname
      subscription_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDonation = /* GraphQL */ `
  mutation UpdateDonation(
    $input: UpdateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    updateDonation(input: $input, condition: $condition) {
      id
      amount
      currency
      payer_id
      email_address
      given_name
      surname
      subscription_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDonation = /* GraphQL */ `
  mutation DeleteDonation(
    $input: DeleteDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    deleteDonation(input: $input, condition: $condition) {
      id
      amount
      currency
      payer_id
      email_address
      given_name
      surname
      subscription_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVodAsset = /* GraphQL */ `
  mutation CreateVodAsset(
    $input: CreateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    createVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      categories
      poster
      createdAt
      updatedAt
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateVodAsset = /* GraphQL */ `
  mutation UpdateVodAsset(
    $input: UpdateVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    updateVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      categories
      poster
      createdAt
      updatedAt
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteVodAsset = /* GraphQL */ `
  mutation DeleteVodAsset(
    $input: DeleteVodAssetInput!
    $condition: ModelVodAssetConditionInput
  ) {
    deleteVodAsset(input: $input, condition: $condition) {
      id
      title
      description
      categories
      poster
      createdAt
      updatedAt
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createVideoObject = /* GraphQL */ `
  mutation CreateVideoObject(
    $input: CreateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    createVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideoObject = /* GraphQL */ `
  mutation UpdateVideoObject(
    $input: UpdateVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    updateVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideoObject = /* GraphQL */ `
  mutation DeleteVideoObject(
    $input: DeleteVideoObjectInput!
    $condition: ModelVideoObjectConditionInput
  ) {
    deleteVideoObject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      name
      email
      country
      phoneType
      aboutMe
      favoriteThings
      courseAims
      favoriteFilmUrl
      linksToWork
      question_1
      question_2
      question_3
      question_4
      question_5
      question_6
      question_7
      question_8
      question_9
      question_10
      question_11
      question_12
      question_13
      question_14
      approved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      name
      email
      country
      phoneType
      aboutMe
      favoriteThings
      courseAims
      favoriteFilmUrl
      linksToWork
      question_1
      question_2
      question_3
      question_4
      question_5
      question_6
      question_7
      question_8
      question_9
      question_10
      question_11
      question_12
      question_13
      question_14
      approved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      name
      email
      country
      phoneType
      aboutMe
      favoriteThings
      courseAims
      favoriteFilmUrl
      linksToWork
      question_1
      question_2
      question_3
      question_4
      question_5
      question_6
      question_7
      question_8
      question_9
      question_10
      question_11
      question_12
      question_13
      question_14
      approved
      createdAt
      updatedAt
      __typename
    }
  }
`;
