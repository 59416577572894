import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

const VideoDeletePopup = ({ show, id, title, handleClose, handleSubmit }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Delete {title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to delete this video?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" size-="lg" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="danger" size-="lg" onClick={() => handleSubmit(id)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
)

export default VideoDeletePopup
