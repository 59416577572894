import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"

const TrainingGraduatesQuote = () => {
  return (
    <Col xs={12} className="bg-primary">
      <div className="py-5 text-center">
        <h3 className="fs-2 text-uppercase fw-bolder text-white">Testimonials</h3>
      </div>
      <Row className="align-items-center pb-5">
        <Col xs={{ span: 6, offset: 3 }} md={{ span: 3, offset: 1 }} className="mb-4">
          <Image
            src="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1632084885/photo5927008178346768395_vfftul.jpg"
            fluid
            roundedCircle
            alt="Picture of Wass"
          />
        </Col>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 7, offset: 0 }}>
          <figure className="text-start">
            <blockquote className="blockquote text-white">
              <p className="fs-4 fst-italic fw-light">
                “75 SHOTS, for me, is an opportunity for LGBTI people to express
                themselves artistically, in countries where being ourselves puts us in
                danger. It is an international connection, which links us together by
                learning new skills and creating films reflecting our stories.
              </p>
              <p className="fs-4 fst-italic fw-light">
                75 SHOTS allowed me to express myself in many forms while working for the
                community. This is what I have wanted for years, to combine art and human
                rights. It also allowed me to communicate with people from different
                countries experiencing the same realities as myself, despite languages,
                time differences or circumstances. I hope that our projects expand into a
                real global artistic LGBTI network.”
              </p>
            </blockquote>
            <figcaption className="pt-2 blockquote-footer text-uppercase text-white">
              <cite title="Source Title">Wacyl</cite>
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="align-items-center bg-secondary py-5">
        <Col
          xs={{ span: 6, order: "first", offset: 3 }}
          md={{ span: 3, order: "last", offset: 0 }}
          className="mb-4"
        >
          <Image
            src="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1632086497/photo5926926183126120079_mo5eez.jpg"
            fluid
            roundedCircle
            alt="Picture of Chikah"
          />
        </Col>
        <Col
          xs={{ span: 10, order: "last", offset: 1 }}
          md={{ span: 7, order: "first", offset: 1 }}
          className="mb-4"
        >
          <figure className="text-start">
            <blockquote className="blockquote text-white">
              <p className="fs-4 fst-italic fw-light">
                “We are a group of queer people from various countries where LGBTQ+ people
                have been criminalized. Here we get to share our authentic stories
                professionally in our own way using our hand devices. We are able to build
                our film skills and be there for one another spreading love and
                positivity. ”
              </p>
            </blockquote>
            <figcaption className="pt-2 blockquote-footer text-uppercase text-white">
              <cite title="Source Title">Chikah</cite>
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="align-items-center bg-dark py-5">
        <Col xs={{ span: 6, offset: 3 }} md={{ span: 3, offset: 1 }} className="mb-4">
          <Image
            src="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1632092771/60days_STILL_sgorfu.png"
            fluid
            roundedCircle
            alt="Picture of Khaled"
          />
        </Col>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 7, offset: 0 }} className="mb-4">
          <figure className="text-start">
            <blockquote className="blockquote text-white">
              <p className="fs-4 fst-italic fw-light">
                “75 SHOTS is like my guardian angel who always appears in adversity; it's
                the thing that keeps me from falling apart. Karin and 75 SHOTS is the
                thing that reminds me that I have a dream that I deserve to be happy, that
                I can make things happen, that I am not a victim...I am a fighter, a
                survivor and that I have people I don't know that are watching my back. 75
                SHOTS is a chosen family.”
              </p>
            </blockquote>
            <figcaption className="pt-2 blockquote-footer text-uppercase text-white">
              <cite title="Source Title">Khaled</cite>
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row className="align-items-center bg-white py-5">
        <Col
          xs={{ span: 6, order: "first", offset: 3 }}
          md={{ span: 3, order: "last", offset: 0 }}
          className="mb-4"
        >
          <Image
            src="https://res.cloudinary.com/pocketcinema/image/upload/ar_1:1,c_fill,g_auto,w_1000/v1629382444/PHOTO-2020-04-19-12-25-32_acswyf.jpg"
            fluid
            roundedCircle
            alt="Picture of Francky"
          />
        </Col>
        <Col
          xs={{ span: 10, order: "last", offset: 1 }}
          md={{ span: 7, order: "first", offset: 1 }}
          className="mb-4"
        >
          <figure className="text-start">
            <blockquote className="blockquote">
              <p className="fs-4 text-secondary fst-italic fw-light">
                “I am Francky Belany, Nigerien but I like to be called a citizen of the
                world. I live in Burkina Faso. 75 SHOTS is a learning refuge for me,
                without discrimination towards age, skin colour or religious beliefs. It’s
                a place of expression for our deepest feelings, our sorrows, our joys and
                our desires regardless of our origines. 75 SHOTS is like a lioness
                protecting her young, whom she follows attentively with a lot of patience
                to guide them on the journeys of life. A professional meeting place,
                learning in mutual respect."
              </p>
              <p className="fs-4 text-secondary fst-italic fw-light">
                "It's beautiful to see what we can achieve thousands of kilometers away,
                without ever having met in person. With an smartphone we create stories
                that speak to the world, that advocate love and human rights. For me, 75
                SHOTS is the friend, the mother, the structure, the association and even
                the stranger that the World needs to get to know for its fight and its
                devotion for a better World and for the LGBTQI+ minorities that we are.”
              </p>
            </blockquote>
            <figcaption className="pt-2 blockquote-footer text-uppercase">
              <cite title="Source Title">Francky Belany</cite>
            </figcaption>
          </figure>
        </Col>
      </Row>
    </Col>
  )
}

export default TrainingGraduatesQuote
