import { uploadFile } from "../../storage/imageStorage"
import { Field, ErrorMessage } from "formik"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import FormFieldImageFile from "../FormFieldImageFile"
import FormErrorMessage from "../FormErrorMessage"

const VideoListItemFormFieldsImage = ({
  touched,
  errors,
  setFieldValue,
  image,
  handleLoading,
}) => {
  const storeFile = async (name, file, level) => {
    handleLoading(true)
    try {
      // store file in S3 Storage bucket
      const key = await uploadFile(file, level)
      // set key as field value
      key && setFieldValue(name, file.name) && handleLoading(false)
      // fetch file to preview
      //fetchFile(key, level)
    } catch (err) {
      console.log("Error storing file: ", err)
    }
  }

  return (
    <Col xs={12}>
      <Image src={image} alt="Screen image" fluid />
      <Field
        name="poster"
        label="Video Poster"
        level="public"
        touched={touched.imgUrl}
        errors={errors.imgUrl}
        handleStoreFile={storeFile}
        component={FormFieldImageFile}
      />
      <ErrorMessage name="poster" component={FormErrorMessage} />
    </Col>
  )
}

export default VideoListItemFormFieldsImage
