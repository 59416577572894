import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import VideoListItemFormFields from "./VideoListItemFormFields"

// Yup
const videoSchema = Yup.object().shape({
  title: Yup.string().max(50, "Title is too long").required("Title is required"),
  description: Yup.string().required("Description is required"),
  categories: Yup.string(),
  poster: Yup.string(),
})

const VideoListItemForm = ({ item, image, handleUpdateItem, handleDeleteVideo }) => {
  const [loading, setLoading] = useState(false)

  return (
    <Formik
      initialValues={{
        title: item.title || "",
        description: item.description || "",
        categories: item.categories || "",
        poster: item.poster || "",
      }}
      validationSchema={videoSchema}
      onSubmit={(values) => {
        const videoData = {
          title: values.title,
          description: values.description,
          categories: values.categories,
          poster: values.poster,
        }

        // add new video
        handleUpdateItem(videoData)
      }}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Row>
            <VideoListItemFormFields
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              image={image}
              handleLoading={setLoading}
            />
            <Col xs={12} sm={{ span: 6, order: 3 }} className="pt-2">
              <Button
                type="submit"
                variant="success"
                size="lg"
                className="mb-2 w-100"
                disabled={loading}
              >
                Save edits
              </Button>
            </Col>
            <Col xs={12} sm={{ span: 6, order: 2 }} className="pt-2">
              <Button
                variant="outline-danger"
                size="lg"
                className="mb-2 w-100"
                onClick={() => handleDeleteVideo(item)}
              >
                Delete video
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default VideoListItemForm
