import React, { /*useEffect,*/ useState } from "react"
// import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js"
import { createNewDonation } from "../../services/donation"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import DonateModal from "./DonateModal"
import DonateBanner from "./DonateBanner"
import PayPerViewAside from "../Home/PayPerViewAside"
import DonatePayPalSubscription from "./DonatePayPalSubscription"
import DonatePayPal from "./DonatePayPal"

const Donate = () => {
  const [show, setShow] = useState(false)
  const [name, setName] = useState("")

  const addNewDonation = async (data) => {
    try {
      const donation = await createNewDonation(data)

      if (donation) {
        const name = donation.given_name
          ? donation.surname
            ? `${donation.given_name} ${donation.surname}`
            : donation.given_name
          : ""

        window.scrollTo(0, 0)
        setShow(true)
        setName(name)

        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (err) {
      // TODO: error handling
      // console.log("addNewDonation error... ", err.message)
    }
  }

  return (
    <Container as="main" fluid="xl" className="mt-72">
      <DonateModal show={show} name={name} />
      <DonateBanner />
      <PayPerViewAside />
      <DonatePayPalSubscription handleAddNewDonation={addNewDonation} />
      <DonatePayPal handleAddNewDonation={addNewDonation} />
      <Row className="m-3">
        <p className="fs-5">
          Donate to 75 SHOTS and support independent cinema and LGBTQ+ artists living in
          oppressive regimes. We rely on generous support from people like you - any
          donation, no matter how small, helps sustain our global grassroots project.
        </p>
      </Row>
    </Container>
  )
}

export default Donate
