import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Nav from "react-bootstrap/Nav"

const HeaderNav = () => {
  const history = useHistory()
  const [key, setKey] = useState("")

  useEffect(() => {
    // user must sign in to access admin
    // when they do, the main navigation
    // changes to admin navigation
    // handleActiveKey sets the active nav link
    // to the corresponding page
    const handleActiveKey = () => {
      const location = history.location.pathname

      switch (location) {
        case "/admin-applications":
          setKey("2")
          break
        case "/admin-donations":
          setKey("3")
          break
        case "/admin-subscriptions":
          setKey("4")
          break
        case "/admin-videos":
          setKey("5")
          break
        case "/":
          setKey("6")
          break
        default:
          setKey("1")
      }
    }

    handleActiveKey()
  }, [history.location.pathname])

  return (
    <Nav className="me-auto" activeKey={key}>
      <Nav.Link as={Link} to="/admin" eventKey="1">
        Dashboard
      </Nav.Link>
      <Nav.Link as={Link} to="/admin-applications" eventKey="2">
        Applications
      </Nav.Link>
      <Nav.Link as={Link} to="/admin-donations" eventKey="3">
        Donations
      </Nav.Link>
      <Nav.Link as={Link} to="/admin-subscriptions" eventKey="4">
        Subscriptions
      </Nav.Link>
      <Nav.Link as={Link} to="/admin-videos" eventKey="5">
        Videos
      </Nav.Link>
      <Nav.Link as={Link} to="/" eventKey="6">
        Website
      </Nav.Link>
    </Nav>
  )
}

export default HeaderNav
