import { generateClient } from "aws-amplify/api"
/* queries and mutations */
import { listNewsletterSubscriptions as ListNewsletterSubscriptions } from "../graphql/queries"
import {
  createNewsletterSubscription as CreateNewsletterSubscription,
  deleteNewsletterSubscription as DeleteNewsletterSubscription,
} from "../graphql/mutations"

const client = generateClient()

export const getNewsletterSubscriptions = async () => {
  try {
    const result = await client.graphql({
      query: ListNewsletterSubscriptions,
    })
    // returns a list of donations
    return result.data.listNewsletterSubscriptions.items ?? []
  } catch (err) {
    console.log("getNewsletterSubscriptions error: ", err)
  }
}

// data = {  email: "", frstname: "", lastname "" }
export const createNewSubscription = async (data) => {
  try {
    const result = await client.graphql({
      query: CreateNewsletterSubscription,
      variables: {
        input: data,
      },
    })
    // create new subscription
    return result.data.createNewsletterSubscription ?? null
  } catch (err) {
    console.log("createNewSubscription error", err)
  }
}

export const removeSubscription = async (id) => {
  try {
    const result = await client.graphql({
      query: DeleteNewsletterSubscription,
      variables: {
        input: { id },
      },
    })
    // get updated subscription
    return result.data.deleteNewsletterSubscription ?? null
  } catch (err) {
    console.log("error deleting a subscription...", err)
  }
}
