import { signOutUser } from "../../services/auth"
import Button from "react-bootstrap/Button"

const AdminHeaderNavUser = ({ user }) => {
  const signOut = async () => {
    try {
      await signOutUser()
    } catch (err) {
      console.log("signOut error: ", err.message)
    }
  }

  return (
    <>
      <Button
        variant="link"
        className="d-none d-md-block text-secondary"
        onClick={signOut}
      >
        Sign Out
      </Button>
      <div className="pt-2 d-md-none">
        <Button
          variant="link"
          className="w-100 d-md-none text-secondary"
          onClick={signOut}
        >
          Sign Out
        </Button>
      </div>
    </>
  )
}

export default AdminHeaderNavUser
