import * as Yup from "yup"
import { Formik, Form } from "formik"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import ApplicationFormFields from "./ApplicationFormFields"

// requered characters form url link
const re =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

// Yup
const applicationSchema = Yup.object().shape({
  name: Yup.string().max(35, "Name is too long").required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email address")
    .required("Contact email is required"),
  country: Yup.string().required("Select country"),
  phoneType: Yup.string().required("Select phone type"),
  aboutMe: Yup.string()
    .min(30, "Minimum length for this entry is 30 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  favoriteThings: Yup.string()
    .min(20, "Minimum length for this entry is 20 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  courseAims: Yup.string()
    .min(30, "Minimum length for this entry is 30 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  favoriteFilmUrl: Yup.string()
    .matches(re, "Must be an URL link")
    .required("Favourite film is required"),
  linksToWork: Yup.string(),
  question_1: Yup.string()
    .min(5, "Minimum length for this entry is 10 characters")
    .max(60, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_2: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_3: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_4: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_5: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_6: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_7: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_8: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_9: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_10: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_11: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_12: Yup.string()
    .min(5, "Minimum length for this entry is 10 characters")
    .max(60, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_13: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
  question_14: Yup.string()
    .min(10, "Minimum length for this entry is 10 characters")
    .max(300, "Maximum length for this entry is 300 characters")
    .required("This entry is required"),
})

const ApplicationForm = ({ countries, phones, handleCreateApplication }) => {
  return (
    <Col xs={12}>
      <Formik
        initialValues={{
          name: "",
          email: "",
          country: "",
          phoneType: "",
          aboutMe: "",
          favoriteThings: "",
          courseAims: "",
          favoriteFilmUrl: "",
          linksToWork: "",
          question_1: "",
          question_2: "",
          question_3: "",
          question_4: "",
          question_5: "",
          question_6: "",
          question_7: "",
          question_8: "",
          question_9: "",
          question_10: "",
          question_11: "",
          question_12: "",
          question_13: "",
          question_14: "",
        }}
        validationSchema={applicationSchema}
        onSubmit={(values, { resetForm }) => {
          const newApplication = {
            name: values.name,
            email: values.email,
            country: values.country,
            phoneType: values.phoneType,
            aboutMe: values.aboutMe,
            favoriteThings: values.favoriteThings,
            courseAims: values.courseAims,
            favoriteFilmUrl: values.favoriteFilmUrl,
            linksToWork: values.linksToWork,
            question_1: values.question_1,
            question_2: values.question_2,
            question_3: values.question_3,
            question_4: values.question_4,
            question_5: values.question_5,
            question_6: values.question_6,
            question_7: values.question_7,
            question_8: values.question_8,
            question_9: values.question_9,
            question_10: values.question_10,
            question_11: values.question_11,
            question_12: values.question_12,
            question_13: values.question_13,
            question_14: values.question_14,
            approved: false,
          }

          // store application data
          handleCreateApplication(newApplication)
          // reset form
          resetForm()
        }}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <Row>
              <ApplicationFormFields
                countries={countries}
                phones={phones}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
              />
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
                className="pt-3"
              >
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="w-100 text-white"
                >
                  Send Application
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Col>
  )
}

export default ApplicationForm
