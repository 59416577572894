import { uploadFile } from "../../storage/imageStorage"
import { Field, ErrorMessage } from "formik"
import Col from "react-bootstrap/Col"
import FormFieldImageFile from "../FormFieldImageFile"
import FormErrorMessage from "../FormErrorMessage"

const AddVideoFormFieldsImage = ({ touched, errors, setFieldValue, handleLoading }) => {
  const storeFile = async (name, file, level) => {
    handleLoading(true)
    try {
      // store file in S3 Storage bucket
      const key = await uploadFile(file, level)
      // set key as field value
      key && setFieldValue(name, file.name) && handleLoading(false)
      // fetch file to preview
      //fetchFile(key, level)
    } catch (err) {
      console.log("Error storing file: ", err)
      handleLoading(false)
    }
  }

  return (
    <Col xs={12}>
      <Field
        name="poster"
        label="Upload Cover Image"
        level="public"
        touched={touched.imgUrl}
        errors={errors.imgUrl}
        handleStoreFile={storeFile}
        component={FormFieldImageFile}
      />
      <ErrorMessage name="poster" component={FormErrorMessage} />
    </Col>
  )
}

export default AddVideoFormFieldsImage
