import { getCurrentUser, signUp, confirmSignUp, signIn, signOut } from "aws-amplify/auth"

// check if a user is logged in when the page is loaded
export const currentAuthenticatedUser = async () => {
  try {
    const response = await getCurrentUser()
    return response
  } catch (err) {
    console.log("error fetching current user", err)
  }
}

// sign up with email and password
export const signUpNewUser = async (user) => {
  try {
    const { isSignUpComplete, userId, nextStep } = await signUp(user)
    console.log("signUpNewUser: ", isSignUpComplete, userId, nextStep)
  } catch (err) {
    console.log("error signing up:", err)
  }
}

// confirm email with code
export const confirmSignUpEmail = async (username, confirmationCode) => {
  try {
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username,
      confirmationCode,
    })
    console.log("confirmSignUpEmail: ", isSignUpComplete, nextStep)
  } catch (err) {
    console.log("error confirming sign up", err)
  }
}

// sign in with email and password
export const signInUser = async (username, password) => {
  try {
    await signIn({ username, password })
  } catch (err) {
    console.log("error signing in: ", err)
  }
}

// sign out user
export const signOutUser = async () => {
  try {
    await signOut({ global: true })
  } catch (err) {
    console.log("error signing out: ", err)
  }
}
