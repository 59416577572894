import { Field, ErrorMessage } from "formik"
import FormFieldText from "../FormFieldText"
import FormFieldTextArea from "../FormFieldTextArea"
import FormErrorMessage from "../FormErrorMessage"

const ApplicationFormFieldsExtra = ({ touched, errors }) => {
  return (
    <>
      <Field
        name="question_1"
        label="What do you do?"
        touched={touched.question_1}
        errors={errors.question_1}
        component={FormFieldText}
      />
      <ErrorMessage name="question_1" component={FormErrorMessage} />
      <Field
        name="question_2"
        label="What steps did you take to get where you are today?"
        touched={touched.question_2}
        errors={errors.question_2}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_2" component={FormErrorMessage} />
      <Field
        name="question_3"
        label="How do you stand out in your field?"
        touched={touched.question_3}
        errors={errors.question_3}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_3" component={FormErrorMessage} />
      <Field
        name="question_4"
        label="Describe a project you are working on right now?"
        touched={touched.question_4}
        errors={errors.question_4}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_4" component={FormErrorMessage} />
      <Field
        name="question_5"
        label="What's your style/perspective/taste? Do you have a project that represents this? (add link)"
        touched={touched.question_5}
        errors={errors.question_5}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_5" component={FormErrorMessage} />
      <Field
        name="question_6"
        label="Which aspect of your filmmaking do you wish you could focus on more?"
        touched={touched.question_6}
        errors={errors.question_6}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_6" component={FormErrorMessage} />
      <Field
        name="question_7"
        label="What is challenging you right now?"
        touched={touched.question_7}
        errors={errors.question_7}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_7" component={FormErrorMessage} />
      <Field
        name="question_8"
        label="Let's bring out the time machine. What do you wish you could have told
              yourself, when, and why?"
        touched={touched.question_8}
        errors={errors.question_8}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_8" component={FormErrorMessage} />
      <Field
        name="question_9"
        label="If you could talk to an expert to gain more insight on something, what would it be about?"
        touched={touched.question_9}
        errors={errors.question_9}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_9" component={FormErrorMessage} />
      <Field
        name="question_10"
        label="What kind of opportunities are you looking for?"
        touched={touched.question_10}
        errors={errors.question_10}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_10" component={FormErrorMessage} />
      <Field
        name="question_11"
        label="Describe your ideal project? The type of projects you would like to work on."
        touched={touched.question_11}
        errors={errors.question_11}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_11" component={FormErrorMessage} />
      <Field
        name="question_12"
        label="Which artist influences you? Who has helped you elevate your work? (add link)"
        touched={touched.question_12}
        errors={errors.question_12}
        component={FormFieldText}
      />
      <ErrorMessage name="question_12" component={FormErrorMessage} />
      <Field
        name="question_13"
        label="How should someone approach you about working together?"
        touched={touched.question_13}
        errors={errors.question_13}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_13" component={FormErrorMessage} />
      <Field
        name="question_14"
        label="How do you stay creative? Where do you get your inspiration from?"
        touched={touched.question_14}
        errors={errors.question_14}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="question_14" component={FormErrorMessage} />
    </>
  )
}

export default ApplicationFormFieldsExtra
