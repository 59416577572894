import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"

// Storybook example: https://paypal.github.io/react-paypal-js/?path=/docs/example-subscriptions--default
// PayPal SDK: https://developer.paypal.com/sdk/js/reference/#createsubscription
const DonatePayPalSubscriptionButton = ({ subscription, handleAddNewDonation }) => {
  const [{ isPending, isRejected }] = usePayPalScriptReducer()
  const currency = "CAD"
  const buttonStyle = {
    layout: "vertical",
    color: "silver",
    tagline: false,
    // label: "subscribe",
  }

  return (
    <Col xs={12} sm={6} md={4} className="mb-4">
      <Card className="h-100 border-primary">
        <Card.Body className="text-center">
          <Card.Title className="py-3 text-primary fs-4">
            CA${subscription.amount} per month
          </Card.Title>
          <Card.Text>{subscription.benefit}</Card.Text>
        </Card.Body>
        <Card.Footer className="border-0 bg-white">
          {isPending ? (
            <Button variant="link" size="lg" className="w-100">
              <Spinner animation="border" variant="secondary" />
            </Button>
          ) : (
            <PayPalButtons
              style={buttonStyle}
              disabled={isRejected}
              forceReRender={[subscription.amount, currency]}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: subscription.planId,
                  name: "Monthly subscription to 75shots.com",
                })
                /*
                .then((orderId) => {
                  // Your code here after create the order
                  return orderId
                })
                */
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  // Save payment details to the server
                  const newDonation = {
                    amount: details.purchase_units[0].amount.value,
                    currency: details.purchase_units[0].amount.currency_code,
                    payer_id: details.payer.payer_id,
                    email_address: details.payer.email_address,
                    given_name: details.payer.name.given_name,
                    surname: details.payer.name.surname,
                    subscription_id: data.subscriptionID,
                  }

                  handleAddNewDonation(newDonation)
                })
              }}
              onCancel={() => {
                //TODO
              }}
              onError={(err) => {
                alert("Transaction was unsuccessful")
              }}
            />
          )}
        </Card.Footer>
      </Card>
    </Col>
  )
}

export default DonatePayPalSubscriptionButton
