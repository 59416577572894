const applicationReducer = (state, action) => {
  switch (action.type) {
    case "SET APPLICATIONS":
      return { applications: action.payload }
    case "UPDATE APPLICATION":
      const id = action.payload.id
      return {
        applications: state.applications.map((item) =>
          item.id !== id ? item : action.payload
        ),
      }
    case "DELETE APPLICATION":
      return {
        applications: state.applications.filter((item) => item.id !== action.payload),
      }
    default:
      return state
  }
}

export default applicationReducer
