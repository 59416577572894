import React, { useState, useEffect } from "react"
import { createNewApplication } from "../../services/application"
import countryList from "../../data/countries"
import phoneList from "../../data/phones"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ApplicationModal from "./ApplicationModal"
import ApplicationForm from "./ApplicationForm"

const Application = () => {
  const [countries, setCountries] = useState([])
  const [phones, setPhones] = useState([])
  const [show, setShow] = useState(false)
  const [name, setName] = useState("")

  useEffect(() => {
    setCountries(countryList)
    setPhones(phoneList)
  }, [])

  // create a new application
  const handleCreateApplication = async (data) => {
    try {
      const response = await createNewApplication(data)

      // notify that application has been submitted
      if (response) {
        window.scrollTo(0, 0)
        setShow(true)
        setName(response.name)

        setTimeout(() => {
          setShow(false)
        }, 5000)
      }
    } catch (err) {
      console.log("New application error... ", err)
    }
  }

  return (
    <Container className="mt-72 mb-5">
      <ApplicationModal show={show} name={name} />
      <Row>
        <Col xs={12} className="pt-4 text-center">
          <h2 className="mb-3 s-2 fw-light text-primary text-uppercase">Pocket Cinema</h2>
          <h3 className="mb-3 fs-2 text-primary">Application Form</h3>
        </Col>
        <ApplicationForm
          countries={countries}
          phones={phones}
          handleCreateApplication={handleCreateApplication}
        />
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          className="pt-3 text-center"
        >
          <h3 className="mb-3 fs-2 text-primary">Introductory Video</h3>
          <p>
            In addition to submitting the application form, please send us a 60 seconds
            selfie video about yourself, your ambitions and why you would be the best
            candidate for the fellowship to{" "}
            <a href="mailto:75shotsmovie@gmail.com">75shotsmovie@gmail.com</a>.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default Application
