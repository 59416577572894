import { generateClient } from "aws-amplify/api"
/* queries and mutations */
import { listDonations as ListDonations } from "../graphql/queries"
import {
  createDonation as CreateDonation,
  deleteDonation as DeleteDonation,
} from "../graphql/mutations"
// import { currentAuthenticatedUser } from "./auth"

const client = generateClient()

// const isGuest = async () => {
//   try {
//     const response = await currentAuthenticatedUser()
//     if (response === undefined) {
//       return true
//     } else {
//       return false
//     }
//   } catch (err) {
//     console.log("isGuest error: ", err)
//   }
// }

export const getDonations = async () => {
  try {
    const result = await client.graphql({
      query: ListDonations,
    })
    // returns a list of donations
    return result.data.listDonations.items ?? []
  } catch (err) {
    console.log("getDonations error: ", err)
  }
}

// data = { amount: "", currency: "", payer_id: "", email_address: "", given_name: "", surname: "" }
export const createNewDonation = async (data) => {
  // const questUser = await isGuest()

  try {
    const result = await client.graphql({
      query: CreateDonation,
      variables: {
        input: data,
      },
      // get newly created donation
      // authMode: questUser ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    })
    return result.data.createDonation ?? null
  } catch (err) {
    console.log("createNewDonation error", err)
  }
}

export const deleteCancelledDonation = async (id) => {
  try {
    const result = await client.graphql({
      query: DeleteDonation,
      variables: {
        input: { id },
      },
    })

    // get deleted asset
    return result.data.deleteDonation ?? null
  } catch (err) {
    console.log("deleteVideoAsset error", err)
  }
}
