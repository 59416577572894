import React from "react"
import { Field, ErrorMessage } from "formik"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import FormFieldText from "../FormFieldText"
import FormFieldEmail from "../FormFieldEmail"
import FormFieldSelect from "../FormFieldSelect"
import FormFieldTextArea from "../FormFieldTextArea"
import FormErrorMessage from "../FormErrorMessage"
import ApplicationFormFieldsExtra from "./ApplicationFormFieldsExtra"

const ApplicationFormFields = ({ countries, phones, touched, errors, setFieldValue }) => {
  return (
    <Col
      xs={{ span: 10, offset: 1 }}
      md={{ span: 8, offset: 2 }}
      lg={{ span: 6, offset: 3 }}
    >
      <Field
        name="name"
        label="Name"
        touched={touched.name}
        errors={errors.name}
        component={FormFieldText}
      />
      <ErrorMessage name="name" component={FormErrorMessage} />
      <Field
        name="email"
        label="Email"
        touched={touched.email}
        errors={errors.email}
        component={FormFieldEmail}
      />
      <ErrorMessage name="email" component={FormErrorMessage} />
      <Field
        name="country"
        label="Where are you from?"
        items={countries}
        setFieldValue={setFieldValue}
        component={FormFieldSelect}
      />
      <ErrorMessage name="country" component={FormErrorMessage} />
      <Field
        name="favoriteThings"
        label="Name 3 things you find beautiful in general?"
        touched={touched.favoriteThings}
        errors={errors.favoriteThings}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="favoriteThings" component={FormErrorMessage} />
      <Field
        name="courseAims"
        label="What do you want to learn the MOST in this course?"
        touched={touched.courseAims}
        errors={errors.courseAims}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="courseAims" component={FormErrorMessage} />
      <Field
        name="aboutMe"
        label="Describe yourself in 1-2 sentences"
        touched={touched.aboutMe}
        errors={errors.aboutMe}
        rows="2"
        component={FormFieldTextArea}
      />
      <ErrorMessage name="aboutMe" component={FormErrorMessage} />
      <Field
        name="favoriteFilmUrl"
        label="Add URL link to your favourite LGBTQ+ film"
        touched={touched.favoriteFilmUrl}
        errors={errors.favoriteFilmUrl}
        component={FormFieldText}
      />
      <ErrorMessage name="favoriteFilmUrl" component={FormErrorMessage} />
      <Field
        name="linksToWork"
        label="Add links to your previous work?"
        touched={touched.linksToWork}
        errors={errors.linksToWork}
        component={FormFieldText}
      />
      <Form.Text className="text-muted">Links should be separated by commas</Form.Text>
      <ErrorMessage name="linksToWork" component={FormErrorMessage} />
      <Field
        name="phoneType"
        label="Do you have a smartphone?"
        setFieldValue={setFieldValue}
        items={phones}
        component={FormFieldSelect}
      />
      <ErrorMessage name="phoneType" component={FormErrorMessage} />
      <ApplicationFormFieldsExtra touched={touched} errors={errors} />
    </Col>
  )
}

export default ApplicationFormFields
