import { Link } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const PayPerViewAside = () => {
  return (
    <Row className="bg-primary text-center">
      <Col xs={{ span: 10, offset: 1 }} className="py-4">
        <Link to="/donate" className="text-decoration-none">
          <h2 className="py-3 lh-1 large-banner-text text-white text-uppercase header-page">
            <span className="fw-bolder">100% of the proceeds go to the artists</span>
          </h2>
        </Link>
      </Col>
    </Row>
  )
}

export default PayPerViewAside

/*<Row className="bg-primary">
  <Col xs={12} className="large-banner text-center text-white text-uppercase">
    <h4 className="large-banner-text">100% of the proceeds go to the artists</h4>
  </Col>
</Row>*/
