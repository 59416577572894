import { Link } from "react-router-dom"
import Col from "react-bootstrap/Col"

const FooterTerms = () => (
  <Col xs={12} md={{ span: 10, offset: 1 }} className="mb-4 text-center text-white">
    <Link to="/code-of-conduct" className="text-white">
      Code of Conduct
    </Link>{" "}
    &#8226;{" "}
    <Link to="/privacy-policy" className="text-white">
      Privacy Policy
    </Link>
  </Col>
)

export default FooterTerms
