import Accordion from "react-bootstrap/Accordion"
import DonationListItem from "./DonationListItem"

const DonationList = ({ donations }) => {
  const donationsByDonor = () => {
    const donors = []

    donations.forEach((donation) => {
      const name = donation.given_name
        ? donation.surname
          ? `${donation.surname}, ${donation.given_name}`
          : donation.given_name
        : ""

      const newDonor = {
        payer_id: donation.payer_id,
        email_address: donation.email_address,
        name,
        donations: [
          {
            amount: donation.amount,
            currency: donation.currency,
          },
        ],
      }

      // check if donor has already been added
      const multipleDonations = donors.find(
        (donor) => donor.payer_id === donation.payer_id
      )

      // add new donor if this is the first donation
      if (donors.length === 0 || !multipleDonations) {
        donors.push(newDonor)
      }

      // add to donations by the donor
      // if this is not the first donation
      if (multipleDonations) {
        donors.map((donor) =>
          donor.payer_id !== multipleDonations.payer_id
            ? donor
            : {
                ...multipleDonations,
                donations: multipleDonations.donations.push({
                  amount: donation.amount,
                  currency: donation.currency,
                }),
              }
        )
      }
    })

    return donors
  }

  return (
    <Accordion>
      {donationsByDonor().map((item, index) => (
        <DonationListItem key={index} index={index} item={item} />
      ))}
    </Accordion>
  )
}

export default DonationList
