import React, { useEffect, useReducer, useState } from "react"
import { getVideos, deleteVideoAsset } from "../../services/video"
import videoReducer from "../../reducers/videoReducer"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import VideoDeletePopup from "./VideoDeletePopup"
import VideoList from "./VideoList"

const AdminVideos = () => {
  const [state, dispatch] = useReducer(videoReducer, { videos: [] })
  const [videoToDelete, setVideoToDelete] = useState(null)

  // fetch video assets from server
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getVideos()
        if (data) {
          dispatch({ type: "SET VIDEOS", payload: data })
        }
      } catch (err) {
        //TODO: error message
      }
    }

    getData()
  }, [])

  const updateVideoList = (data) => {
    dispatch({ type: "UPDATE VIDEO", payload: data })
  }

  const deleteVideo = async (id) => {
    try {
      // this only deletes the video asset
      // not the video itself
      const response = await deleteVideoAsset(id)

      if (response) {
        // update local state
        dispatch({ type: "DELETE VIDEO", payload: id })
        // close modal
        setVideoToDelete(null)
      }
    } catch (err) {
      //TODO: error message
    }
  }

  return (
    <Container as="section" className="mb-5">
      {videoToDelete && (
        <VideoDeletePopup
          show={videoToDelete}
          id={videoToDelete.id}
          title={videoToDelete.title}
          handleClose={() => setVideoToDelete(null)}
          handleSubmit={deleteVideo}
        />
      )}
      <Row className="g-0">
        <Col xs={12} className="mb-3 text-center">
          <h3 className="mb-3">Video</h3>
        </Col>
        <Col xs={12} mdm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          {state.videos.length > 0 ? (
            <VideoList
              videos={state.videos}
              handleUpdateVideoList={updateVideoList}
              handleDeleteVideo={setVideoToDelete}
            />
          ) : (
            <p>No videos</p>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default AdminVideos
