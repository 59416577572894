import Form from "react-bootstrap/Form"

const FormFieldImageFile = ({
  field,
  label,
  level,
  touched,
  errors,
  handleStoreFile,
}) => (
  <Form.Group className="mb-1 pt-3">
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type="file"
      size="lg"
      accept=".png, .jpg"
      isInvalid={touched && errors}
      onChange={(e) => handleStoreFile(field.name, e.currentTarget.files[0], level)}
    />
    <Form.Text className="text-muted">
      Must be in JPG or PNG format (aspect ratio of 16:9)
    </Form.Text>
  </Form.Group>
)

export default FormFieldImageFile
