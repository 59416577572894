import { Link } from "react-router-dom"
import Col from "react-bootstrap/Col"
import Logo from "../Logo"

const FooterLogo = () => (
  <Col
    xs={{ span: 4, offset: 4 }}
    sm={{ span: 2, offset: 5 }}
    className="my-4 text-center text-white"
  >
    <Link to="/">
      <Logo />
    </Link>
  </Col>
)

export default FooterLogo
