import Container from "react-bootstrap/Container"
import SignInForm from "../SignIn/SignInForm"

const AdminSignIn = () => (
  <Container className="mt-72 mb-5">
    <div className="pt-4 text-center">
      <h3>Login to Admin Dashboard</h3>
    </div>
    <SignInForm />
  </Container>
)

export default AdminSignIn
