import { Facebook, Instagram } from "react-bootstrap-icons"

const HeaderSocial = () => {
  return (
    <div className="text-end">
      <a
        className="mx-2 py-2"
        href="https://www.facebook.com/75shots"
        target="_blank"
        rel="noreferrer"
      >
        <Facebook color="#4267B2" size={36} />
      </a>
      <a
        className="mx-2 py-2"
        href="https://www.instagram.com/75shotsmovie/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram color="#dd2a7b" size={36} />
      </a>
    </div>
  )
}

export default HeaderSocial
