import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const ProductionsHeader = () => {
  return (
    <Row className="py-5 px-3">
      <Col xs={12}>
        <h2 className="mb-4 fs-2 text-uppercase">
          <span className="fw-bolder">75 Shots Productions</span>
          <br />
          <span className="fw-lighter">100% of the proceeds go to the filmmakers</span>
        </h2>
        <p>
          Our vision underlines our humanity with{" "}
          <span className="fst-italic">art and activism</span> at the intersection. By
          recognizing our differences & commonality, we develop new interactive ways of
          producing & showcasing films that illustrate the richness of our lived
          experiences.
        </p>
        <p>
          We are committed to bringing audiences thoughtful and compelling stories from a
          variety of filmmakers and perspectives. We are creating a portal for us to share
          unsung stories, in order to evolve and be seen by a greater audience both here &
          abroad.
        </p>
        {/*
          <p>
            SIGN UP to start watching videos made by under-represented LGBTQ+ creators.
          </p>
        */}
        <p>
          Join a community who cares about the decriminalization of homosexuality
          worldwide.
        </p>
        {/*
          <p>
            75 SHOTS ON DEMAND offers you the possibility of contributing to this cause
            actively, by streaming original content, starting @$2.99 
          </p>
        */}
      </Col>
    </Row>
  )
}

export default ProductionsHeader
