import { uploadData, getUrl } from "aws-amplify/storage"

// configure Storage
// Storage.configure({
//   Auth: {
//     region: config.aws_project_region, // REQUIRED - Amazon Cognito Region
//     userPoolId: config.aws_user_pools_id,
//     userPoolWebClientId: config.aws_user_pools_web_client_id,
//     mandatorySignIn: false,
//   },
//   AWSS3: {
//     bucket: "75shotsc5619881a34f45c19de58ffedba1aeae64710-staging",
//     region: config.aws_project_region,
//   },
// })

// upload image files to S3 Storage bucket
export const uploadFile = async (file, level) => {
  // place the file inside the image folder,
  const key = `images/${file.name}`

  try {
    const result = await uploadData({
      key,
      data: file,
      options: {
        accessLevel: level, // defaults to `guest` but can be 'private' | 'protected' | 'guest'
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(
              `Upload progress ${Math.round(transferredBytes / totalBytes) * 100} %`
            )
          }
        }, // Optional progress callback.
      },
    }).result
    // returns the file key
    return result.key
    // OLD: const response = await Storage.put(key, file, {
    //   progressCallback(progress) {
    //     const percent = Math.round((progress.loaded / progress.total) * 100)
    //     console.log("loding status: ", percent + "%")
    //   },
    //   level: level,
    //   contentType: file.type,
    // })
  } catch (err) {
    console.log("File upload error: ", err)
  }
}

// download image files from S3 Storage bucket
export const downloadFile = async (key, level) => {
  try {
    // const getUrlResult =
    await getUrl({
      key,
      options: {
        accessLevel: level, // can be 'private', 'protected', or 'guest' but defaults to `guest
      },
    })
    // const response = await Storage.get(key, { level: level })
    // return response
  } catch (err) {
    console.log("File download error: ", err)
  }
}

// import { list } from 'aws-amplify/storage';

// try {
//   const result = await list({
//     prefix: 'photos/',
//     options: {
//       accessLevel: 'protected'
//     }
//   });
// } catch (error) {
//   console.log(error);
// }
// import { remove } from 'aws-amplify/storage';

// try {
//   await remove({
//     key: filename,
//     options: {
//       accessLevel: 'protected'
//     }
//   });
// } catch (error) {
//   console.log('Error ', error);
// }
