/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewsletterSubscription = /* GraphQL */ `
  query GetNewsletterSubscription($id: ID!) {
    getNewsletterSubscription(id: $id) {
      id
      email
      firstname
      lastname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNewsletterSubscriptions = /* GraphQL */ `
  query ListNewsletterSubscriptions(
    $filter: ModelNewsletterSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstname
        lastname
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDonation = /* GraphQL */ `
  query GetDonation($id: ID!) {
    getDonation(id: $id) {
      id
      amount
      currency
      payer_id
      email_address
      given_name
      surname
      subscription_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDonations = /* GraphQL */ `
  query ListDonations(
    $filter: ModelDonationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDonations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        currency
        payer_id
        email_address
        given_name
        surname
        subscription_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVodAsset = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      title
      description
      categories
      poster
      createdAt
      updatedAt
      video {
        id
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listVodAssets = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelVodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        categories
        poster
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoObject = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideoObjects = /* GraphQL */ `
  query ListVideoObjects(
    $filter: ModelVideoObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      name
      email
      country
      phoneType
      aboutMe
      favoriteThings
      courseAims
      favoriteFilmUrl
      linksToWork
      question_1
      question_2
      question_3
      question_4
      question_5
      question_6
      question_7
      question_8
      question_9
      question_10
      question_11
      question_12
      question_13
      question_14
      approved
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        country
        phoneType
        aboutMe
        favoriteThings
        courseAims
        favoriteFilmUrl
        linksToWork
        question_1
        question_2
        question_3
        question_4
        question_5
        question_6
        question_7
        question_8
        question_9
        question_10
        question_11
        question_12
        question_13
        question_14
        approved
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
