import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"

const FormFieldTextCompact = ({ field, label, placeholder, touched, errors }) => {
  return (
    <Form.Group>
      <FloatingLabel label={label}>
        <Form.Control
          type="text"
          {...field}
          placeholder={placeholder}
          isInvalid={touched && errors}
        />
      </FloatingLabel>
    </Form.Group>
  )
}

export default FormFieldTextCompact
