import Modal from "react-bootstrap/Modal"

const DonateModal = ({ show, name }) => (
  <Modal show={show} className="text-center">
    <Modal.Body>
      Thank you{name ? ` ${name}` : ""}! Your donation is greatly appreciated.
    </Modal.Body>
  </Modal>
)

export default DonateModal
