import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DonatePayPalButton from "./DonatePayPalButton"

const DonatePayPal = ({ handleAddNewDonation }) => {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    //"client-id": "test",
    currency: "CAD",
    intent: "capture",
    //debug: true,
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <Row className="mx-3 mt-5">
        <Col xs={12} className="mb-3 text-center">
          <h3 className="mb-4 text-uppercase fw-bold">Make a one time donation</h3>
        </Col>
        <DonatePayPalButton handleAddNewDonation={handleAddNewDonation} />
      </Row>
    </PayPalScriptProvider>
  )
}

export default DonatePayPal
