import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
//import TemplatePageTeaser from "../TemplatePageTeaser"
//import TemplatePageIntro from "../TemplatePageIntro"
import OnceUponATime from "./OnceUponATime"
//import AboutStory from "./AboutStory"
//import AboutFounder from "./AboutFounder"
//import AboutCommunity from "./AboutCommunity"
//import AboutProgram from "./AboutProgram"
//import AboutBoard from "./AboutBoard"
import AboutVideo from "./AboutVideo"

const About = () => (
  <Container as="main" className="mt-72 overflow-hidden">
    <Row>
      <Col xs={12} className="p-0">
        <Image
          src="https://res.cloudinary.com/pocketcinema/image/upload/v1632092771/60days_STILL_sgorfu.png"
          alt="header image"
          fluid
        />
      </Col>
      <Col xs={{ span: 10, offset: 1 }} className="my-5">
        <h4 className="pb-2 fs-5 text-uppercase">Our Mission</h4>
        <div className="pt-4 pt-md-1">
          <p>
            75 SHOTS is a member-driven non-profit organization with the mission to fight
            against the persecution and criminalization of LGBTQ+ artists from the
            sixty-seven countries in which homosexuality is still criminalized.
          </p>
          <p>
            We empower disenfranchised LGBTQ+ voices and expand mainstream storytelling
            narratives on our online platforms.
          </p>
          <p>
            Using digital media and art, our productions create international communities
            through collaborations, mentorships and development of artistic practices in a
            safe environment.
          </p>
        </div>
      </Col>
    </Row>
    <Row className="bg-primary py-4">
      <Col xs={{ span: 10, offset: 1 }}>
        <h2 className="py-5 lh-1 text-white text-uppercase header-page">
          <span className="fw-bolder">We fight against </span>
          <span className="fw-lighter">the persecution of LGBTQ+ artists worldwide</span>
        </h2>
      </Col>
    </Row>
    {/*<Row>
      <TemplatePageTeaser
        boldText="We fight against"
        plainText="the persecution of LGBTQ+ artists worldwide"
      />
      <TemplatePageIntro title="Our Mission">
        <p>
          75 SHOTS is a member-driven non-profit organization with the mission to fight
          against the persecution and criminalization of LGBTQ+ artists worldwide.
        </p>
        <p>
          We empower disenfranchised LGBTQ+ voices and expand mainstream storytelling
          narratives on our online platforms.
        </p>
        <p>
          Using digital media and art, our productions create international communities
          through collaborations, mentorships and development of artistic practices in a
          safe environment.
        </p>
      </TemplatePageIntro>
    </Row>*/}
    {/*<AboutFounder />
    <AboutStory />*/}
    <OnceUponATime />
    {/*<AboutCommunity />
    <AboutProgram />
    <AboutBoard />*/}
    <AboutVideo />
  </Container>
)

export default About
