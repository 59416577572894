import Accordion from "react-bootstrap/Accordion"
import ApplicationListItem from "./ApplicationListItem"

const ApplicationList = ({
  applications,
  approved,
  handleUpdateApplicationList,
  handleConfirmDeleteApplication,
}) => (
  <Accordion className="mb-3">
    {applications.length > 0 &&
      applications.map((item, index) => (
        <ApplicationListItem
          key={index}
          item={item}
          index={index}
          handleUpdateApplicationList={handleUpdateApplicationList}
          handleConfirmDeleteApplication={handleConfirmDeleteApplication}
        />
      ))}
    {applications.length === 0 ? (
      approved ? (
        <p>No approved applications</p>
      ) : (
        <p>No pending applications</p>
      )
    ) : null}
  </Accordion>
)

export default ApplicationList
