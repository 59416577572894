import React, { useEffect, useReducer } from "react"
import { getDonations } from "../../services/donation"
import donationReducer from "../../reducers/donationReducer"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DonationList from "./DonationList"

const AdminDonations = () => {
  const [state, dispatch] = useReducer(donationReducer, { donations: [] })

  // fetch donations from server
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getDonations()

        if (data) {
          dispatch({ type: "SET DONATIONS", payload: data })
        }
      } catch (err) {
        //TODO: error message
      }
    }

    getData()
  }, [])

  return (
    <Container as="section" className="mb-5">
      <Row className="g-0">
        <Col xs={12} className="mb-3 text-center">
          <h3 className="mb-3">Donations</h3>
        </Col>
        <Col xs={12} mdm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          {state.donations.length > 0 ? (
            <DonationList donations={state.donations} />
          ) : (
            <p>No donations</p>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default AdminDonations
