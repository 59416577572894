import React, { useEffect, useReducer, useState } from "react"
import { getApplications, removeApplication } from "../../services/application"
import applicationReducer from "../../reducers/applicationReducer"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ApplicationDeleteModal from "./ApplicationDeleteModal"
import ApplicationList from "./ApplicationList"

const AdminApplications = () => {
  const [state, dispatch] = useReducer(applicationReducer, { applications: [] })
  const [show, setShow] = useState(false)
  const [applicationId, setApplicationId] = useState("")

  const updateApplicationList = (data) => {
    dispatch({ type: "UPDATE APPLICATION", payload: data })
  }

  // fetch applications from server
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getApplications()
        if (data) {
          dispatch({ type: "SET APPLICATIONS", payload: data })
        }
      } catch (err) {
        //TODO: error message
      }
    }

    getData()
  }, [])

  const sortApplicationsByApproved = (status) => {
    if (state.applications && state.applications.length > 0) {
      const sortedApplications = state.applications.filter(
        (item) => item.approved === status
      )
      if (sortedApplications.length > 0) {
        return sortedApplications
      } else {
        return []
      }
    } else {
      return []
    }
  }

  const deleteApplication = async () => {
    try {
      const response = await removeApplication(applicationId)

      if (response) {
        dispatch({ type: "DELETE APPLICATION", payload: applicationId })
        setShow(false)
        setApplicationId("")
      }
    } catch (err) {
      //TODO: error handling
    }
  }

  const confirmDeleteApplication = (id) => {
    setShow(true)
    setApplicationId(id)
  }

  return (
    <Container as="section" className="mb-5">
      <ApplicationDeleteModal
        show={show}
        handleClose={() => setShow(false)}
        handleDeleteApplication={deleteApplication}
      />
      <Row className="g-0">
        <Col xs={12} className="mb-3 text-center">
          <h3 className="mb-3">Applications</h3>
        </Col>
        <Col xs={12} mdm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <h4>Pending</h4>
          <ApplicationList
            applications={sortApplicationsByApproved(false)}
            approved={false}
            handleUpdateApplicationList={updateApplicationList}
            handleConfirmDeleteApplication={confirmDeleteApplication}
          />
        </Col>
        <Col xs={12} mdm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <h4>Approved</h4>
          <ApplicationList
            applications={sortApplicationsByApproved(true)}
            approved={true}
            handleUpdateApplicationList={updateApplicationList}
            handleConfirmDeleteApplication={confirmDeleteApplication}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default AdminApplications
