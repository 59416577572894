import React, { useEffect, useState } from "react"
import { getVideo } from "../../services/video"
//import { PlayCircle } from "react-bootstrap-icons"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
//import Card from "react-bootstrap/Card"
//import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import videoImg from "../../media/img_75shots_6.jpg"
import VideoPlayer from "../VideoPlayer"

const AboutVideo = () => {
  //const [play, setPlay] = useState(false)
  const [asset, setAsset] = useState(null)

  useEffect(() => {
    // get video asset form storage
    const getData = async () => {
      try {
        const data = await getVideo("1e9dfb44-0b3f-4cd9-adeb-2e53400070ef")
        data && setAsset(data)
      } catch (err) {
        console.log(err)
      }
    }
    getData()
  }, [])

  return (
    <Row>
      {asset ? (
        <Col xs={12} className="bg-dark p-0">
          <VideoPlayer
            src={`https://productions-staging-output-6tlstdtw.s3.eu-west-2.amazonaws.com/public/${asset.video.id}/${asset.video.id}.m3u8`}
            poster={videoImg}
          />
          {/*<video width="100%" height="auto" controls autoplay="true" poster={videoImg}>
            <source src={videoLink} type="video/mp4"></source>
            <p>
              Your browser doesn't support HTML5 video. Here is a{" "}
              <a href="myVideo.mp4">link to the video</a> instead.
            </p>
            <track kind="captions"></track>
          </video>*/}
        </Col>
      ) : (
        <Col xs={12} className="bg-dark p-0">
          <Image src={videoImg} className="w-100" />
          {/*<Card className="bg-dark text-white border-0" style={{ borderRadius: 0 }}>
            <Card.Img
              src="https://res.cloudinary.com/pocketcinema/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1631994528/photo5920003395924637318_co975n.jpg"
              alt="Group of people gathered around a red box"
              style={{ borderRadius: 0 }}
            />
            <Card.ImgOverlay style={{ background: "rgba(0, 0, 0, 0.4)" }}>
              <Row className="h-100 align-items-center">
                <Col xs={12} className="text-center">
                  <Card.Title>
                    <Button
                      variant="ligth"
                      className="text-white"
                      style={{ marginTop: -15 }}
                      onClick={() => setPlay(true)}
                    >
                      <PlayCircle size={42} />
                    </Button>
                    <span className="fs-2 fw-bolder">
                      Leopard never changes its spots
                    </span>
                  </Card.Title>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Card>*/}
        </Col>
      )}
    </Row>
  )
}

export default AboutVideo
